import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Loading from "../components/Loading";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Globals from "../parameters/globals";
import "../scss/contact.scss";
import { useLocation } from "react-router-dom";
import GeneralContext from "../context/GeneralContext";

import {
	EReCaptchaV2Size,
	EReCaptchaV2Theme,
	ReCaptchaV2,
} from "react-recaptcha-x";

import { useContext } from "react";

function Contact(props) {
  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [ragioneSociale, setRagioneSociale] = useState("");
  const [curriculum, setCurriculum] = useState();
  const [IsCompany, setIsCompany] = useState(false);
  const [message, setmessage] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [newsletter, setNewsletter] = useState(false);

  const [is_robot, set_is_robot] = useState(false);
  const [is_robot_general, set_is_robot_general] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [schedule, setSchedule] = useState([]);
  const { lang } = useContext(GeneralContext);

  // const getMapsAndSchedule = async () => {
  // 	try {
  // 		const response = await axios.get(Globals.GETWORKSCHEDULE + "&lang=" + lang);
  // 		setSchedule(response.data.work_schedule);
  // 	} catch (err) {
  // 		console.log(err);
  // 	}
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let derivazione = props.general ? "Lavora con noi" : props.from;

    if (token === "") {
    	setErrMsg(Globals.language.errorLabel.acceptRecaptcha[lang]);
    	return false;
    }

    try {
      setLoading(true);
      let body = {
        name: name,
        surname: surname,
        email: email,
        phone: phone,
        ragioneSociale: ragioneSociale,
        IsCompany: IsCompany === "true" ? true : false,
        message: message,
        privacy: privacy,
        newsletter: newsletter,
        curriculum: props.general
          ? {
              name: curriculum.name,
              size: curriculum.size,
              type: curriculum.type,
            }
          : "",
        derivazione: derivazione,
        token: token
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));
      if (props.general) {
        formdata.append("curriculum", curriculum);
      }

      if (props.general) {
        var response = await axios.post(
          Globals.WORKWITHUS + "&lang=" + lang,
          formdata,
          {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          }
        );
      } else {
        var response = await axios.post(
          Globals.CONTACT + "&lang=" + lang,
          formdata
          // {
          // 	headers: { "Content-Type": "application/json" },
          // 	withCredentials: true,
          // }
        );
      }

      // TODO: remove console.logs before deployment
      // console.log(JSON.stringify(response?.data));
      //console.log(JSON.stringify(response))

      if (response?.data?.error === true) {
        setErrMsg(response.data.message);

        setLoading(false);
        setSuccess(false);
      } else {
        //clear state and controlled inputs
        setLoading(false);
        setSuccess(true);
      }
    } catch (err) {
      setLoading(false);
      setSuccess(false);
      setErrMsg(Globals.language.errorLabel.serverError[lang]);
    }
  };

  // useEffect(() => {
    // if (location.pathname === "/") {
    // 	set_is_robot_general(true);
    // } else {
    // 	set_is_robot(true);
    // }
    // if (props.general === true) {
    // 	getMapsAndSchedule();
    // }
    // return () => {
    // 	set_is_robot(false);
    // 	set_is_robot_general(false);
    // };
  // }, [lang]);

  const v2Callback = (token) => {
  	if (typeof token === "string") {
  		setToken(token);
  	} else if (typeof token === "boolean" && !token) {
  		setErrMsg(Globals.language.errorLabel.recaptchaExpired[lang]);
  		setToken("");
  	} else if (token instanceof Error) {
  		setErrMsg(Globals.language.errorLabel.serverError[lang]);
  		setToken("");
  	}
  };

  if (props.general) {
    return (
      <div className="container-fluid" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-sm text-center">
              <h1 className="div-heading display-4 title-homepage-all">
                {/* {Globals.language.contacts.titleCustomers[lang]} */}
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              {loading ? (
                <Loading />
              ) : success ? (
                <div className="p-2 text-center">
                  {" "}
                  <Card className="p-5" style={{ fontSize: "25px" }}>
                    <i
                      className="bi bi-check-circle"
                      style={{ fontSize: "50px", color: "green" }}
                    ></i>
                    <h2>
                      {Globals.language.contacts.sendSuccessCurriculum[lang]}
                    </h2>
                    <h4>{Globals.language.contacts.sendTextSuccess[lang]}</h4>
                  </Card>
                </div>
              ) : (
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      {Globals.language.contacts.nameForm[lang]} <span style={{color:"#e83e30"}}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="name"
                      placeholder={
                        Globals.language.contacts.nameFormPlaceholder[lang]
                      }
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      {Globals.language.contacts.surnameForm[lang]} <span style={{color:"#e83e30"}}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="surname"
                      placeholder={
                        Globals.language.contacts.surnameFormPlaceholder[lang]
                      }
                      value={surname}
                      onChange={(e) => setsurname(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      {Globals.language.contacts.emailForm[lang]} <span style={{color:"#e83e30"}}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={
                        Globals.language.contacts.emailFormPlaceholder[lang]
                      }
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      {Globals.language.contacts.phoneForm[lang]} <span style={{color:"#e83e30"}}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="phone"
                      placeholder={
                        Globals.language.contacts.phoneFormPlaceholder[lang]
                      }
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      {Globals.language.contacts.messaggeForm[lang]} <span style={{color:"#e83e30"}}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      as="textarea"
                      placeholder={
                        Globals.language.contacts
                          .messaggeFormPlaceholderCurriculum[lang] 
                      }
                      value={message}
                      onChange={(e) => setmessage(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      {Globals.language.contacts.nameCurriculum[lang]} <span style={{color:"#e83e30"}}>*</span>
                    </Form.Label>
                          {/* <input
                      type="file"
                      name="curriculum"
                      id="curriculum"
                      accept="application/pdf"
                      data-placeholder={Globals.language.contacts.nameFormCurriculum[lang]}
                      onChange={(e) => setCurriculum(e.target.files[0])}
                      required
                    /> */}
                    <div>
                      <Form.Control
                        type="file"
                        name="curriculum"
                        id="curriculum"
                        accept="application/pdf"
                        data-placeholder={
                          Globals.language.contacts.nameFormCurriculum[lang]
                        }
                        onChange={(e) => setCurriculum(e.target.files[0])}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label={
                        <span>
                          {Globals.language.contacts.privacyFormFirst[lang]}
                          <a href={"https://readynet.it/" + lang + "/privacy"} target="_blank">
                            privacy{" "}
                          </a>
                          {Globals.language.contacts.privacyFormSecond[lang]} <span style={{color:"#e83e30"}}>*</span>
                        </span>
                      }
                      onChange={(e) =>
                        e.target.value === "on"
                          ? setPrivacy(true)
                          : setPrivacy(false)
                      }
                      required
                    />
                  </Form.Group>
                  {/* <Form.Group className='mb-3' controlId='formBasicCheckbox'>
										<Form.Check
											type='checkbox'
											label={Globals.language.contacts.newsletterForm[lang]}
											onChange={(e) =>
												e.target.value === "on" ? setNewsletter(true) : setNewsletter(false)
											}
										/>
									</Form.Group> */}

                  {/* <Form.Group
										className='position-relative'
										controlId='formBasicCheckbox'>
										<ReCaptchaV2
											callback={v2Callback}
											theme={EReCaptchaV2Theme.Light}
											size={EReCaptchaV2Size.Normal}
											id='my-id'
											data-test-id='my-test-id'
											tabindex={0}
										/>
									</Form.Group> */}
                    <Form.Group className='position-relative' controlId='formBasicCheckbox'>
							<ReCaptchaV2
								callback={v2Callback}
								theme={EReCaptchaV2Theme.Light}
								size={EReCaptchaV2Size.Normal}
								id='my-id'
								data-test-id='my-test-id'
								tabindex={0}
							/>
              <div>
              <span style={{color:"#e83e30"}}>*</span>  {Globals.language.contacts.campoObbligatorio[lang]}
              </div>
						</Form.Group>

                  <Button
                    variant="outline-danger"
                    className="d-flex justify-content-center m-auto mt-3 p-3"
                    style={{ width: "50%", fontSize: "20px", fontWeight: 600 }}
                    type="submit"
                  >
                    {Globals.language.contacts.sendButtonForm[lang]}
                  </Button>
                  {errMsg && <p> {errMsg} </p>}
                </Form>
              )}
            </div>

            {/* <div className='col-lg-6 col-md-12 col-sm-12'>
							<div className='card my-2 mx-1 color-card-contact w-100'>
								<div className='my-5 responsive-information-contact'>
									<div className='col-md-1 text-center'>
										<i
											className='bi bi-geo-alt'
											style={{ fontSize: "30px", color: "#cd3426" }}></i>
									</div>
									<div className='col-md-5'>
										<article className='border-about-us mx-2 min-vh-25'>
											<div className='text-start mx-3'>
												<strong>{Globals.language.contacts.sedeOperativa[lang]} </strong>
												<p>{schedule.operational_headquarters}</p>
												<strong>{Globals.language.contacts.phone[lang]} </strong>
												<p>{schedule.phone}</p>
												<strong>{Globals.language.contacts.email[lang]} </strong>
												<p>{schedule.email}</p>
											</div>
										</article>
									</div>
									<div className='col-md-1 text-center'>
										<i
											className='bi bi-clock'
											style={{ fontSize: "30px", color: "#cd3426" }}></i>
									</div>
									<div className='col-md-5'>
										<article className='border-about-us mx-2 min-vh-25 w-100'>
											<div className='text-start mx-3 '>
												<strong>{Globals.language.contacts.hours[lang]}</strong>
												<div className='d-flex'>
													<div className='col-md-6'>
														<p>{Globals.language.contacts.monday[lang]}</p>
														{schedule.schedule_mon_fri}
													</div>
													<div className='col-md-6'></div>
												</div>

												<pre></pre>
												<p>
													{Globals.language.contacts.saturday[lang]}{" "}
													<span> {schedule.schedule_sat} </span>
												</p>
												<p>
													<strong>
														{" "}
														{Globals.language.contacts.sunday[lang]} {schedule.schedule_sun}
													</strong>
												</p>
											</div>
										</article>
									</div>
								</div>
							</div>
							<div
								id='map-container-google-1'
								className='z-depth-1-half map-container mb-2'
								style={{ height: "500px" }}>
								<iframe
									src={"https://maps.google.com/maps?q=" + schedule.maps}
									style={{ border: 0 }}
									allowFullScreen></iframe>
							</div> 
						</div>*/}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {loading ? (
          <Loading />
        ) : success ? (
          <div className="p-2 text-center">
            {" "}
            <Card style={{ fontSize: "25px" }}>
              <i
                className="bi bi-check-circle"
                style={{ fontSize: "50px", color: "green" }}
              ></i>
              <h2>{Globals.language.contacts.sendSuccess[lang]}</h2>
              <h4>{Globals.language.contacts.sendTextSuccess[lang]}</h4>
            </Card>
          </div>
        ) : (
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {Globals.language.contacts.nameForm[lang]} <span style={{color:"#e83e30"}}>*</span>
              </Form.Label>
              <Form.Control
                type="name"
                placeholder={
                  Globals.language.contacts.nameFormPlaceholder[lang]
                }
                value={name}
                onChange={(e) => setname(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {Globals.language.contacts.surnameForm[lang]} <span style={{color:"#e83e30"}}>*</span>
              </Form.Label>
              <Form.Control
                type="surname"
                placeholder={
                  Globals.language.contacts.surnameFormPlaceholder[lang]
                }
                value={surname}
                onChange={(e) => setsurname(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {Globals.language.contacts.emailForm[lang]} <span style={{color:"#e83e30"}}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder={
                  Globals.language.contacts.emailFormPlaceholder[lang]
                }
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                {Globals.language.contacts.phoneForm[lang]} <span style={{color:"#e83e30"}}>*</span>
              </Form.Label>
              <Form.Control
                type="phone"
                placeholder={
                  Globals.language.contacts.phoneFormPlaceholder[lang]
                }
                value={phone}
                onChange={(e) => setphone(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{Globals.language.contacts.ragione[lang]}</Form.Label>
              <Form.Control
                type="ragione"
                placeholder={
                  Globals.language.contacts.ragioneFormPlaceholder[lang]
                }
                value={ragioneSociale}
                onChange={(e) => setRagioneSociale(e.target.value)}
              />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Select onChange={(e) => setIsCompany(e.target.value)}>
                <option value={false}>
                  {Globals.language.contacts.privateForm[lang]}
                </option>
                <option value={true}>
                  {Globals.language.contacts.companyForm[lang]}
                </option>
              </Form.Select>
            </Form.Group> */}

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                {Globals.language.contacts.messaggeForm[lang]} <span style={{color:"#e83e30"}}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={
                  Globals.language.contacts.messaggeFormPlaceholder[lang]
                }
                as="textarea"
                value={message}
                onChange={(e) => setmessage(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label={
                  <span>
                    {Globals.language.contacts.privacyFormFirst[lang]}
                    <a href={"https://readynet.it/" +lang + "/privacy"} target="_blank">
                      privacy{" "}
                    </a>
                    {Globals.language.contacts.privacyFormSecond[lang]} <span style={{color:"#e83e30"}}>*</span>
                  </span>
                }
                onChange={(e) =>
                  e.target.value === "on" ? setPrivacy(true) : setPrivacy(false)
                }
                required
              />
            </Form.Group>
            {/* <Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<Form.Check
								type='checkbox'
								label={Globals.language.contacts.newsletterForm[lang]}
								onChange={(e) =>
									e.target.value === "on" ? setNewsletter(true) : setNewsletter(false)
								}
							/>
						</Form.Group> */}

            <Form.Group className='position-relative' controlId='formBasicCheckbox'>
							<ReCaptchaV2
								callback={v2Callback}
								theme={EReCaptchaV2Theme.Light}
								size={EReCaptchaV2Size.Normal}
								id='my-id'
								data-test-id='my-test-id'
								tabindex={0}
							/>
						</Form.Group>
            <div>
              <span style={{color:"#e83e30"}}>*</span>  {Globals.language.contacts.campoObbligatorio[lang]}
              </div>

            <Button
              variant="outline-danger"
              className="d-flex justify-content-center mt-3 p-3 mx-auto"
              style={{ width: "25%", fontSize: "20px", fontWeight: 600 }}
              type="submit"
            >
              {Globals.language.contacts.sendButtonForm[lang]}
            </Button>
            {errMsg && <p> {errMsg} </p>}
          </Form>
        )}
      </>
    );
  }
}
export default Contact;
