import React, { useState, useEffect, useContext, useRef } from "react";
import HomeImage from "../images/Home.png";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Globals from "../parameters/globals";
import "../scss/detailhome.scss";
import anime from "animejs/lib/anime.es.js";
import GeneralContext from "../context/GeneralContext";
import Contact from "./Contact";
import { Helmet } from "react-helmet";

function DetailHome() {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [detailProduct, setDetailProduct] = useState([]);
  const [detailPage, setDetailPage] = useState([]);
  const [nomeServizio, setNomeServizio] = useState([]);
  const [imageBg, setImageBg] = useState([]);
  const anchorRef = useRef(null);

  const navigate = useNavigate();
  const { lang } = useContext(GeneralContext);

  let { id } = useParams();
  useEffect(() => {
    getDetailServices();
  }, [lang]);

  const getDetailServices = async () => {
    try {
      let body = {
        id: id,
        access_token: localStorage.getItem("accessToken")
          ? localStorage.getItem("accessToken")
          : "",
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));
      const response = await axios.post(
        Globals.GETDETAILPRODUCT + "&lang=" + lang,
        formdata,
        {
          withCredentials: true,
        }
      );

      // const response = await axios.post(
      //   Globals.GETDETAILPRODUCT + "&lang=" + lang,
      //   formdata,
      //   {
      //     headers: { "Content-Type": "application/json" },
      //     withCredentials: true,
      //   }
      // );

      if (response?.data?.error === true) {
        console.log("sono in errorre");
        setError(true);
        setMessage(response?.data?.error);
      } else {
        setDetailProduct(response?.data?.detailProduct || []);
        setDetailPage(response?.data?.detailPage || []);
        setImageBg(response?.data?.immagini_detail_page || []);
        setNomeServizio(response?.data?.nomeServizi || []);

        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    } catch (err) {
      setError(true);
      setMessage(err);
    }
  };

  const animationRef = React.useRef(null);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 300 + 30 * i,
    });
  }, []);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16 .letter",
      translateY: [0, -100],
      opacity: [1, 0],
      easing: "easeInExpo",
      duration: 1200,
      delay: (el, i) => 100 + 30 * i,
    });
  }, []);
  const goToObject = () => {
    const offset = -150; // Numero di pixel da alzare
    const top =
      anchorRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      offset;
    window.scrollTo({ top, behavior: "smooth" });
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {"Ready net - " +(lang === "it"
            ? detailPage.nome_it
            : lang === "en"
            ? detailPage.nome_en
            : detailPage.nome_ru)}
        </title>

        <link rel="canonical" href="https://www.readynet.it/" />
      </Helmet>
      <div>
        <div
          className="bg"
          style={{
            backgroundImage: "url(" + imageBg + ")",
            width: "100%",
            height: "100%",
          }}
        ></div>
        <div className="content">
          <header>
            <h1 className="title-principal ml16">
              {" "}
              {lang === "it"
                ? detailPage.nome_it
                : lang === "en"
                ? detailPage.nome_en
                : detailPage.nome_ru}
            </h1>
            <p>
              {lang === "it"
                ? detailPage.desc_it
                : lang === "en"
                ? detailPage.titolo_en
                : detailPage.titolo_ru}
            </p>
          </header>
        </div>
        <button className="arrow" onClick={() => goToObject()}></button>
      </div>
      <section className="bg-light shadow-sm" ref={anchorRef} id="anchor">
        <div className="container-fluid">
          <div className="row">
            {detailProduct.map((item, index) => (
              <div className="col-lg-6 d-flex align-items-stretch p-0 border-radius-none">
                <div className="canvas">
                  {/* <div className="canvas_border">
			<svg>
				<defs><linearGradient id="grad-orange" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style={{stopColor:"red", stopOpacity:1}}></stop><stop offset="100%" style={{stopColor:"red", stopOpacity:1}}></stop></linearGradient><linearGradient id="grad-red" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="0%" stop-color="#D34F48"></stop><stop offset="100%" stop-color="#772522"></stop></linearGradient></defs>
				<rect id="rect-grad" className="rect-gradient" fill="none" stroke="url(#grad-orange)" stroke-linecap="square" stroke-width="4" stroke-miterlimit="30" width="100%" height="100%"></rect>
			</svg>
		</div> */}
                  <div className="canvas_img-wrapper">
                    <img className="canvas_img" src={item.fileUrl} alt="" />
                  </div>
                  <div className="canvas_copy_pre p-2">
                    <strong className="canvas_copy_title_pre">
                      {/* <div className="canvas_border">
                    <svg>
				<defs><linearGradient id="grad-orange" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style={{stopColor:"red", stopOpacity:1}}></stop><stop offset="100%" style={{stopColor:"red", stopOpacity:1}}></stop></linearGradient><linearGradient id="grad-red" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="0%" stop-color="#D34F48"></stop><stop offset="100%" stop-color="#772522"></stop></linearGradient></defs>
				<rect id="rect-grad" className="rect-gradient" fill="none" stroke="url(#grad-orange)" stroke-linecap="square" stroke-width="4" stroke-miterlimit="30" width="100%" height="100%"></rect>
			</svg>
      </div> */}
                      {lang === "it"
                        ? item.nome_it
                        : lang === "en"
                        ? item.nome_en
                        : item.nome_ru}
                    </strong>
                    <div className="canvas_copy_details_pre">
                      {lang === "it"
                        ? item.short_desc_it
                        : lang === "en"
                        ? item.short_desc_en
                        : item.short_desc_ru}
                    </div>
                  </div>
                  <div className="canvas_copy">
                    <span className="canvas_copy_subtitle">
                      {lang === "it"
                        ? item.hashtag_desc_it
                        : lang === "en"
                        ? item.hashtag_desc_en
                        : item.hashtag_desc_ru}
                    </span>
                    <strong className="canvas_copy_title">
                      {lang === "it"
                        ? item.nome_it
                        : lang === "en"
                        ? item.nome_en
                        : item.nome_ru}
                    </strong>
                    <strong className="canvas_copy_details text-center">
                      {lang === "it"
                        ? item.desc_it
                        : lang === "en"
                        ? item.desc_en
                        : item.desc_ru}
                    </strong>
                    {/* <strong className="canvas_copy_title">{lang === "it"
                      ? detail.nome_it
                      : lang === "en"
                      ? detail.nome_en
                      : detail.nome_ru}</strong> */}
                    <span className="canvas_copy_details text-center">
                      {parseInt(item.mostra_dettaglio) !== 0 ? (
                        <button
                          className="btn btn-danger mt-5"
                          // onClick={() => (window.location.href = item.link)}

                          onClick={
                            () =>
                              navigate(
                                "/" +
                                  (lang === "it"
                                    ? nomeServizio.it.toLowerCase()
                                    : lang === "en"
                                    ? nomeServizio.en.toLowerCase()
                                    : nomeServizio.ru.toLowerCase()) +
                                  "-" +
                                  (lang === "it" ? "dettaglio" : "detail") +
                                  "/" +
                                  item.id +
                                  "/" +
                                  (lang === "it"
                                    ? item.nome_it
                                        .replace(/\s/g, "-")
                                        .toLowerCase()
                                    : item.nome_en
                                        .replace(/\s/g, "-")
                                        .toLowerCase())
                              )

                            // navigate(
                            //   "/" +
                            //     (lang === "it"
                            //       ? "dettaglio" + "-" + nomeServizio.it
                            //       : "detail" +
                            //         "-" +
                            //         (lang === "en"
                            //           ? nomeServizio.en
                            //           : nomeServizio.ru)) +
                            //     "/" +
                            //     item.id +
                            //     "/" +
                            //     (lang === "it"
                            //       ? item.nome_it
                            //           .replace(/\s/g, "-")
                            //           .toLowerCase()
                            //       : item.nome_en
                            //           .replace(/\s/g, "-")
                            //           .toLowerCase())
                            // )
                          }
                        >
                          {" "}
                          {Globals.language.detailHome.btn_visit_website[lang]}
                        </button>
                      ) : (
                        <div>
                          <a
                            href="#form"
                            className="btn btn-danger mt-5"
                            // onClick={() => (window.location.href = item.link)}
                          >
                            {" "}
                            {Globals.language.detailHome.btn_contact[lang]}{" "}
                          </a>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* {detailProduct.map((item, index) => (
          <div id="form">
            {parseInt(item.mostra_dettaglio) !== 1 ? (
              <div className="container col-lg-12 col-md-6 col-sm-12 pt-5 pb-5">
                <div className="column">
                  <div>
                    <h1 className="title-about-us pb-3 text-center">
                    {Globals.language.contacts.titleProject[lang]}{" "}

                    </h1>
                  </div>
                  <div>
                    <h5 className="pb-3 text-center">
                    {Globals.language.contacts.descProject[lang]}{" "}
                    </h5>
                  </div>
                  <div className="container-fluid">
                    <Contact />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ))} */}
        <div id="form">
          <div className="container col-xl-12 col-lg-6 pt-5 pb-5">
            <div>
              <h1 className="title-about-us pb-3 text-center">
                {Globals.language.contacts.titleProject[lang]}{" "}
              </h1>
            </div>
            <div>
              <h5 className="pb-3 text-center">
                {Globals.language.contacts.descProject[lang]}{" "}
              </h5>
            </div>
            <div className="container-fluid">
              <Contact />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DetailHome;
