import React, { useState, useEffect, useContext, useRef } from "react";
import anime from "animejs/lib/anime.es.js";
import axios from "axios";
import Globals from "../parameters/globals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneralContext from "../context/GeneralContext";
import { Helmet } from "react-helmet";

function CustomerSupport() {
  const { lang } = useContext(GeneralContext);
  const [assistenzaClienti, setAssistenzaClienti] = useState([]);
  const [prodottiAssistenzaClienti, setProdottiAssistenzaClienti] = useState(
    []
  );
  const anchorRef = useRef(null);
  const [imageBg, setImageBg] = useState([]);
  useEffect(() => {
    getAssistenzaClienti();
  }, [lang]);

  const getAssistenzaClienti = async () => {
    try {
      const response = await axios.get(Globals.GETASSISTENZACLIENTI);
      setAssistenzaClienti(response?.data?.assistenza_clienti);
      setProdottiAssistenzaClienti(response?.data?.prodotti_assistenza);
      setImageBg(response?.data?.image_bg);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } catch (err) {
      console.log("errore");
    }
  };
  const redirectToExternalSite = (site) => {
    if (site.includes("https://") || site.includes("http://")) {
      window.open(site, "_blank ");
    } else {
      window.open("https://" + site, "_blank ");
    }
  };
  const animationRef = React.useRef(null);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 300 + 30 * i,
    });
  }, []);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16 .letter",
      translateY: [0, -100],
      opacity: [1, 0],
      easing: "easeInExpo",
      duration: 1200,
      delay: (el, i) => 100 + 30 * i,
    });
  }, []);
  const goToObject = () => {
    const offset = -100; // Numero di pixel da alzare
    const top =
      anchorRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      offset;
    window.scrollTo({ top, behavior: "smooth" });
  };
  return (
    <div>
       <h1 style={{display:"none"}}>
       {Globals.language.meta_title.assistenza[lang]}
      </h1>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Globals.language.meta_title.assistenza[lang]}</title>
        <meta name="description" content={Globals.language.meta_title.assistenza_desc[lang]} />
        <meta name="keywords" content={Globals.language.meta_title.assistenza_key[lang]} />
        <link
          rel="canonical"
          href="https://readynet.it/assistenza-clienti"
        />
      </Helmet>
      <div>
        <div
          className="bg"
          style={{
            backgroundImage: "url(" + imageBg + ")",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        ></div>
        <div className="content">
          <header>
            <h1 className="title-principal ml16">
              {lang === "it"
                ? assistenzaClienti.titolo_it
                : lang === "en"
                ? assistenzaClienti.titolo_en
                : assistenzaClienti.titolo_ru}{" "}
            </h1>
            <p className="desc-principal">
              {lang === "it"
                ? assistenzaClienti.desc_it
                : lang === "en"
                ? assistenzaClienti.desc_en
                : assistenzaClienti.desc_ru}{" "}
            </p>
          </header>
        </div>
        <button className="arrow" onClick={() => goToObject()}></button>
      </div>
      <div className="container-fluid" ref={anchorRef} id="anchor">
        <div className="row">
          {prodottiAssistenzaClienti.map((item, key) => (
            <div
              key={key}
              className="col-lg-6 d-flex align-items-stretch p-0 border-radius-none"
            >
              <div
                className="card rounded-0"
                style={{ width: "100%", height: "600px" }}
              >
                <div className="card-body d-flex flex-column text-center justify-content-center align-items-center align-content-center">
                  <h5 className="card-title">
                    {" "}
                    <FontAwesomeIcon
                      icon={item.icons_fortawesome}
                      style={{
                        color: "#000",
                        fontSize: "45px",
                      }}
                    />
                    {/* <FontAwesomeIcon
                      icon={faComputer}
                      style={{
                        color: "#000",
                        fontSize: "45px",
                      }}
                    /> */}
                  </h5>
                  <div className="circle-home"></div>
                  <p
                    className="card-text mb-4"
                    style={{ fontSize: "54px", fontWeight: "bold" }}
                  >
                    {lang === "it"
                      ? item.title_card_it
                      : lang === "en"
                      ? item.title_card_en
                      : item.title_card_ru}
                  </p>
                  <p
                    style={{
                      fontSize: "30px",
                      lineHeight: 1,
                      width: "350px",
                    }}
                    className="card-text mb-4"
                  >
                    {lang === "it"
                      ? item.desc_card_it
                      : lang === "en"
                      ? item.desc_card_en
                      : item.desc_card_}
                  </p>
                  {item.attachments !== "" && item.attachments !== undefined ? (
                    <>
                      {parseInt(item.attachments[0].active_link) === 1 ? (
                        <a
                          className="rounded-0 btn btn-outline-danger mt-2 w-25 p-3 responsive-btn-home"
                          onClick={() =>
                            redirectToExternalSite(
                              item?.attachments[0].description
                            )
                          }
                          target="_blank"
                        >
                          {item.attachments[0].title !== ""
                            ? item.attachments[0].title
                            : ""}
                        </a>
                      ) : (
                        <a
                          className="rounded-0 btn btn-outline-danger mt-2 w-25 p-3 responsive-btn-home"
                          href={item.attachments[0].url}
                          download
                        >
                          {item.attachments[0].title !== ""
                            ? item.attachments[0].title
                            : ""}
                        </a>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CustomerSupport;
