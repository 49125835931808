import React, { useState, useEffect, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import anime from "animejs/lib/anime.es.js";
import axios from "axios";
import Globals from "../parameters/globals";
import GeneralContext from "../context/GeneralContext";
import "../scss/contactUs.scss";
import Contact from "../components/Contact";
import { Helmet } from "react-helmet";

function ContactUs() {
  const parse = require("html-react-parser");
  const { lang } = useContext(GeneralContext);
  const [contactContent, setContactContent] = useState([]);
  const [imageBg, setImageBg] = useState([]);
  const [imageMap, setImageMap] = useState([]);
  const anchorRef = useRef(null);

  useEffect(() => {
    getContactPage();
  }, [lang]);

  const getContactPage = async () => {
    try {
      const response = await axios.get(Globals.GETCONTACTPAGE);
      setContactContent(response?.data?.contact_page);
      setImageBg(response?.data?.image_bg || []);
      setImageMap(response?.data?.image_map || []);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } catch (err) {
      console.log("errore");
    }
  };
  const animationRef = React.useRef(null);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 300 + 30 * i,
    });
  }, []);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16 .letter",
      translateY: [0, -100],
      opacity: [1, 0],
      easing: "easeInExpo",
      duration: 1200,
      delay: (el, i) => 100 + 30 * i,
    });
  }, []);
  const goToObject = () => {
    const offset = -145; // Numero di pixel da alzare
    const top =
      anchorRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      offset;
    window.scrollTo({ top, behavior: "smooth" });
  };
  return (
    <div>
       <h1 style={{display:"none"}}>
      {Globals.language.meta_title.contatti[lang]}
      </h1>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Globals.language.meta_title.contatti[lang]}</title>
        <meta name="description" content={Globals.language.meta_title.contatti_desc[lang]} />
        <meta name="keywords" content={Globals.language.meta_title.contatti_key[lang]} />
        <link rel="canonical" href="https://readynet.it/contatti" />
      </Helmet>
      <div>
        <div
          className="bg"
          style={{
            backgroundImage: "url(" + imageBg + ")",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        ></div>
        <div className="content">
          <header>
            <h1 className="title-principal ml16">
              {lang === "it"
                ? contactContent.titolo_it
                : lang === "en"
                ? contactContent.titolo_en
                : contactContent.titolo_ru}{" "}
            </h1>
            <p className="desc-principal">
              {lang === "it"
                ? contactContent.desc_it
                : lang === "en"
                ? contactContent.desc_en
                : contactContent.desc_ru}{" "}
            </p>
          </header>
        </div>
        <button className="arrow" onClick={() => goToObject()}></button>
      </div>
      <div className="container-fluid" ref={anchorRef} id="anchor">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 p-0">
            <div className="p-5">
              {lang === "it" ? (
                <div className="d-flex justify-content-center align-items-center">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: contactContent.orari_it,
                    }}
                  ></p>
                </div>
              ) : lang === "en" ? (
                <div className="d-flex justify-content-center align-items-center">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: contactContent.orari_en,
                    }}
                  ></p>
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: contactContent.orari_ru,
                    }}
                  ></p>
                </div>
              )}

              {/* 
          {lang === "it"
                ? contactContent.orari_it
                : lang === "en"
                ? contactContent.orari_en
                : contactContent.orari_ru}{" "} */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 p-0 col-maps">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.5349575134887!2d10.983038276646077!3d45.43887503512758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f5f5ae313bf7b%3A0x8b938d4560742c2c!2sReady%20Net%20Srl!5e0!3m2!1sit!2sit!4v1686820201462!5m2!1sit!2sit"
              width="100%"
              height="500"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            {/* <div
              className="bg"
              style={{
                backgroundImage: "url(" + imageMap + ")",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            ></div> */}
          </div>
        </div>
      </div>
      <div className="container col-lg-12 col-md-6 col-sm-12  pt-5 pb-5">
        <div className="column">
          <div>
            <h1 className="title-about-us pb-3 text-center">
              {Globals.language.contacts.titleScrivici[lang]}{" "}
            </h1>
          </div>
          <div>
            <h5 className="pb-3 w-75 mx-auto text-center">
              {Globals.language.contacts.descScrivici[lang]}{" "}
            </h5>
          </div>

          <div className="container-fluid">
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
