import {useEffect, useContext, useState} from "react";
import { Routes, Route,useLocation } from "react-router-dom";
import DetailHome from "./components/DetailHome";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import CustomerSupport from "./pages/CustomerSupport";
import ErrorPage from "./pages/ErrorPage";
import Homepage from "./pages/Homepage";
import DetailProductHome from "./components/DetailProductHome";
import WorkWithUs from "./pages/WorkWithUs";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import ContactTermOfUse from "./pages/ContactTermOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import Cookiebar from "./components/Cookiebar";
import ScrollButton from "./components/ScrollButton";
import Partner from "./pages/Partner";
import { ReCaptchaProvider } from "react-recaptcha-x";
import Globals from "./parameters/globals";
import EmailDisclaimer from "./pages/EmailDisclaimer";
// import generateSitemap from "./pages/SitemapBuilder";
import Loading from "./components/Loading";
import axios from "axios";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);
function modifyCookie() {
  document.getElementById("container").style.display = "block";
}
function App() {
  const [redirectList, setRedirectList] = useState("");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true); // Stato per il caricamento

  // useEffect(() => {
  //   generateSitemap();
  // }, []);
  const getRedirectList = async () => {
		try {
			const response = await axios.get(Globals.CHECKREDIRECT301, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			});
			setRedirectList(response?.data?.redirect);
		} catch (err) {
			setRedirectList("");
		}
	};

	const checkRedirectList = async () => {

		if (redirectList.length > 0 && redirectList !== "") {
			let redirect_element =
				redirectList.find(
					(element) => element?.old_url === window.location.href,
				) !== undefined
					? redirectList.find((element) => element?.old_url === window.location.href)
					: false;

			if (redirect_element !== false) {
				updateRedirectTable(redirect_element);
			}
		}
    setIsLoading(false); 

	};

	const updateRedirectTable = async (redirect_element) => {
		try {
			let formdata = new FormData();
			let body = {
				id: redirect_element.id,
			};

			formdata.append("body", JSON.stringify(body));
			const response = await axios.post(Globals.UPDATEREDIRECTCOUNTER, formdata, {
				withCredentials: true,
			});

			if (response?.data?.error === false) {
				window.location.replace(redirect_element.new_url);
			}
		} catch (error) {
			console.log(error);
		}
	};

  useEffect(() => {
		checkRedirectList();
	}, [location, redirectList]);


  useEffect(() => {
		getRedirectList();
	}, []);


  return (
    <>
       {isLoading ? (
        <div><Loading/></div> // Componente di caricamento
      ) : (
      <ReCaptchaProvider
        siteKeyV2={Globals.recaptcha_v2_key}
        langCode="it"
        hideV3Badge={false}
      >
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/chi-siamo" element={<AboutUs />} />
          <Route path="/area">
            <Route path=":id/:slug" element={<DetailHome />} />
          </Route>
          <Route path="/:servizio-dettaglio">
            <Route path=":id/:slug" element={<DetailProductHome />} />
          </Route>
          <Route path="/assistenza-clienti" element={<CustomerSupport />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/lavora-con-noi" element={<WorkWithUs />} />
          <Route path="/contatti" element={<ContactUs />} />
          <Route path="/" element={<Homepage />} />

          <Route path="*" element={<ErrorPage />} />

          <Route path="it/" element={<Homepage />} />
          <Route path="it/chi-siamo" element={<AboutUs />} />
          <Route path="it/area">
            <Route path=":id/:slug" element={<DetailHome />} />
          </Route>
          <Route path="it/dettaglio">
            <Route path=":id/:slug" element={<DetailProductHome />} />
          </Route>
          <Route path="it/assistenza-clienti" element={<CustomerSupport />} />
          <Route path="it/lavora-con-noi" element={<WorkWithUs />} />
          <Route path="it/partner" element={<Partner />} />
          <Route path="it/contatti" element={<ContactUs />} />
          <Route path="it/condizioni-utilizzo" element={<ContactTermOfUse />} />
          <Route path="it/privacy" element={<PrivacyPolicy />} />
          <Route path="it/cookie-policy" element={<CookiePolicy />} />
          <Route path="it/email-disclaimer" element={<EmailDisclaimer />} />


          <Route path="en/" element={<Homepage />} />
          <Route path="en/chi-siamo" element={<AboutUs />} />
          <Route path="en/section">
            <Route path=":id/:slug" element={<DetailHome />} />
          </Route>
          <Route path="en/detail">
            <Route path=":id/:slug" element={<DetailProductHome />} />
          </Route>
          <Route path="en/assistenza-clienti" element={<CustomerSupport />} />
          <Route path="en/partner" element={<Partner />} />
          <Route path="en/lavora-con-noi" element={<WorkWithUs />} />
          <Route path="en/contatti" element={<ContactUs />} />
          <Route path="en/condizioni-utilizzo" element={<ContactTermOfUse />} />
          <Route path="en/privacy" element={<PrivacyPolicy />} />
          <Route path="en/cookie-policy" element={<CookiePolicy />} />
          <Route path="en/email-disclaimer" element={<EmailDisclaimer />} />


          <Route path="ru/" element={<Homepage />} />
          <Route path="ru/chi-siamo" element={<AboutUs />} />
          <Route path="ru/section">
            <Route path=":id/:slug" element={<DetailHome />} />
          </Route>
          <Route path="ru/detail">
            <Route path=":id/:slug" element={<DetailProductHome />} />
          </Route>
          <Route path="ru/assistenza-clienti" element={<CustomerSupport />} />
          <Route path="ru/partner" element={<Partner />} />
          <Route path="ru/lavora-con-noi" element={<WorkWithUs />} />
          <Route path="ru/contatti" element={<ContactUs />} />
          <Route path="ru/condizioni-utilizzo" element={<ContactTermOfUse />} />
          <Route path="ru/privacy" element={<PrivacyPolicy />} />
          <Route path="ru/cookie-policy" element={<CookiePolicy />} />
          <Route path="ru/email-disclaimer" element={<EmailDisclaimer />} />


          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <ScrollButton></ScrollButton>
        <Cookiebar />

        <Footer modifyCookie={modifyCookie.bind()} />
      </ReCaptchaProvider>
       )}
    </>
  );
}

export default App;
