import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Globals from "../parameters/globals";
import "../scss/detailhome.scss";
import anime from "animejs/lib/anime.es.js";
import GeneralContext from "../context/GeneralContext";
import Contact from "./Contact";
import { Helmet } from "react-helmet";

function DetailProductHome() {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [detailProduct, setDetailProduct] = useState([]);
  const [nomeServizio, setNomeServizio] = useState([]);

  //   const [detailPage, setDetailPage] = useState([]);
  //   const [imageBg, setImageBg] = useState([]);

  const navigate = useNavigate();
  const { lang } = useContext(GeneralContext);

  const redirectToExternalSite = (site) => {
    if (site.includes("https://") || site.includes("http://")) {
      window.open(site, "_blank ");
    } else {
      window.open("https://" + site, "_blank ");
    }
  };

  let { id } = useParams();
  useEffect(() => {
    getDetailProductHome();
  }, [lang]);

  const getDetailProductHome = async () => {
    try {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, 100);

      let body = {
        id: id,
        access_token: localStorage.getItem("accessToken")
          ? localStorage.getItem("accessToken")
          : "",
      };

      let formdata = new FormData();

      formdata.append("body", JSON.stringify(body));
      const response = await axios.post(
        Globals.GETDETAILPRODUCTHOME + "&lang=" + lang,
        formdata,
        {
          withCredentials: true,
        }
      );

      if (response?.data?.error === true) {
        console.log("sono in errorre");
        setError(true);
        setMessage(response?.data?.error);
      } else {
        setDetailProduct(response?.data?.detailProduct || []);
        setNomeServizio(response?.data?.nomeServizi || []);
        // setDetailPage(response?.data?.detailPage || []);
        // setImageBg(response?.data?.immagini_detail_page || []);
      }
    } catch (err) {
      setError(true);
      setMessage(err);
    }
  };

  const animationRef = React.useRef(null);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 300 + 30 * i,
    });
  }, []);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16 .letter",
      translateY: [0, -100],
      opacity: [1, 0],
      easing: "easeInExpo",
      duration: 1200,
      delay: (el, i) => 100 + 30 * i,
    });
  }, []);
  return (
    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {"Ready net - " + (lang === "it"
            ? detailProduct.nome_it
            : lang === "en"
            ? detailProduct.nome_en
            : detailProduct.nome_ru) +
            " - " +
            (lang === "it"
              ? nomeServizio.it
              : lang === "en"
              ? nomeServizio.en
              : nomeServizio.ru)
            }
        </title>
        <meta
          name="description"
          content={
            lang === "it"
              ? detailProduct.short_desc_it
              : lang === "en"
              ? detailProduct.short_desc_en
              : detailProduct.short_desc_ru
          }
        />
        <meta
          name="keywords"
          content={
            lang === "it"
              ? nomeServizio.it
              : lang === "en"
              ? nomeServizio.en
              : nomeServizio.ru
          }
        />

        <link rel="canonical" href="https://www.readynet.it/" />
      </Helmet>
      <div>
        <div
          style={{
            backgroundColor: "#e83e30",
            width: "100%",
            height: "130px",
          }}
        ></div>
      </div>
      <section className="bg-light shadow-sm">
        <div className="container-fluid">
          {/* <button><i className="fa fa-arrow-left"></i></button> */}

          {/* <span className="flex-start bg-secondary mx-1">
            <button
              onClick={() => navigate(-1)}
              className="btn-back-fix"
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-arrow-left"></i>
            </button>
          </span> */}

          <div className="row">
            <div className="col-lg-6 d-flex align-items-stretch p-0 border-radius-none ">
              <div className="column text-left p-5">
                <h1 className="title-about-us pb-3">
                  {lang === "it"
                    ? detailProduct.nome_it
                    : lang === "en"
                    ? detailProduct.nome_en
                    : detailProduct.nome_ru}
                </h1>
                <p className="lead">
                  {lang === "it"
                    ? detailProduct.desc_it
                    : lang === "en"
                    ? detailProduct.desc_en
                    : detailProduct.desc_ru}
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-stretch p-0 border-radius-none">
              <div className="column text-left p-5">
                <h1 className="title-about-us pb-3">
                  {lang === "it"
                    ? detailProduct.title_progetto_it
                    : lang === "en"
                    ? detailProduct.title_progetto_en
                    : detailProduct.title_progetto_ru}
                </h1>
                <p className="lead">
                  {lang === "it"
                    ? detailProduct.desc_progetto_it
                    : lang === "en"
                    ? detailProduct.desc_progetto_en
                    : detailProduct.desc_progetto_ru}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 column text-center p-5">
                <h1 className="title-about-us pb-3">
                  {lang === "it"
                    ? detailProduct.title_soluzione_it
                    : lang === "en"
                    ? detailProduct.title_soluzione_en
                    : detailProduct.title_soluzione_ru}
                </h1>
                <p className="lead">
                  {lang === "it"
                    ? detailProduct.desc_soluzione_it
                    : lang === "en"
                    ? detailProduct.desc_soluzione_en
                    : detailProduct.desc_soluzione_ru}
                </p>
              </div>
            </div>

            <div className="container-fluid">
              <div id="div_wrapper">
                <div
                  className="div_1"
                  style={{ marginTop: 50, marginBottom: 50 }}
                >
                  <div className="div_2">
                    <div
                      className="image-bg"
                      style={{
                        backgroundImage:
                          "url(" + detailProduct.imgProgetto + ")",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            {detailProduct.link_website_it !== "" && (
              <div className="mx-auto text-center my-4">
                <a
                  className="rounded-0 btn btn-danger mt-2 w-25 p-3 responsive-btn-home"
                  onClick={() =>
                    redirectToExternalSite(
                      lang === "it"
                        ? detailProduct.link_website_it
                        : lang === "en"
                        ? detailProduct.link_website_en
                        : detailProduct.link_website_ru
                    )
                  }
                  target="_blank"
                >
                  Visita il sito <i className="fa fa-external-link mx-2 "></i>
                </a>
              </div>
            )}

            {/* <div className="container-fluid">
              <div id="div_wrapper">
                <div
                  className="div_1"
                  style={{ marginTop: 50, marginBottom: 50 }}
                >
                  <div className="div_2">
                    <div
                      className="image-bg"
                      style={{
                        backgroundImage:
                          "url(" + detailProduct.imgSoluzione + ")",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="container col-lg-12 col-md-6 col-sm-12 pt-5 pb-5">
          <div className="column">
            <div>
              <h1 className="title-about-us pb-3 text-center">
                {Globals.language.contacts.titleProject[lang]}{" "}
              </h1>
            </div>
            <div>
              <h5 className="pb-3 text-center lead">
                {Globals.language.contacts.descProject[lang]}{" "}
              </h5>
            </div>

            <div className="container-fluid">
              <Contact />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DetailProductHome;
