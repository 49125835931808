import React, { useState, useEffect, useContext, useRef } from "react";
import AboutUsImage from "../images/assistenza.jpg";
import anime from "animejs/lib/anime.es.js";
import axios from "axios";
import Globals from "../parameters/globals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneralContext from "../context/GeneralContext";
import Contact from "../components/Contact";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

function WorkWithUs() {
  const parse = require("html-react-parser");
  const { lang } = useContext(GeneralContext);
  const [lavoraConNoi, setLavoraConNoi] = useState([]);
  const [prodottiLavoraConNoi, setProdottiLavoraConNoi] = useState([]);
  const [imageBg, setImageBg] = useState([]);
  const anchorRef = useRef(null);

  useEffect(() => {
    getLavoraConNoi();
  }, [lang]);

  const getLavoraConNoi = async () => {
    try {
      const response = await axios.get(Globals.GETLAVORACONNOI);
      setLavoraConNoi(response?.data?.lavora_con_noi);
      setProdottiLavoraConNoi(response?.data?.prodotti_lavora_con_noi);
      setImageBg(response?.data?.image_bg);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } catch (err) {
      console.log("errore");
    }
  };
  const animationRef = React.useRef(null);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 300 + 30 * i,
    });
  }, []);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16 .letter",
      translateY: [0, -100],
      opacity: [1, 0],
      easing: "easeInExpo",
      duration: 1200,
      delay: (el, i) => 100 + 30 * i,
    });
  }, []);
  const goToObject = () => {
    const offset = -100; // Numero di pixel da alzare
    const top =
      anchorRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      offset;
    window.scrollTo({ top, behavior: "smooth" });
  };
  return (
    <div>
       <h1 style={{display:"none"}}>
      {Globals.language.meta_title.lavora_con_noi[lang]}
      </h1>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Globals.language.meta_title.lavora_con_noi[lang]}</title>
        <meta name="description" content={Globals.language.meta_title.lavora_con_noi_desc[lang]} />
        <meta name="keywords" content={Globals.language.meta_title.lavora_con_noi_key[lang]} />
        <link
          rel="canonical"
          href="https://readynet.it/lavora-con-noi"
        />
      </Helmet>
      <div>
        <div
          className="bg"
          style={{
            backgroundImage: "url(" + imageBg + ")",
            width: "100%",
            height: "100%",
          }}
        ></div>
        <div className="content">
          <header>
            <h1 className="title-principal ml16">
              {lang === "it"
                ? lavoraConNoi.titolo_it
                : lang === "en"
                ? lavoraConNoi.titolo_en
                : lavoraConNoi.titolo_ru}
            </h1>
            <p className="desc-principal">
              {lang === "it"
                ? lavoraConNoi.desc_it
                : lang === "en"
                ? lavoraConNoi.desc_en
                : lavoraConNoi.desc_ru}
            </p>
          </header>
        </div>
        <button className="arrow" onClick={() => goToObject()}></button>
      </div>
      <div
        className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5"
        ref={anchorRef}
        id="anchor"
      >
        <h1 className="title-about-us pb-3">
          {lang === "it"
            ? lavoraConNoi.title_posizioni_it
            : lang === "en"
            ? lavoraConNoi.title_posizioni_en
            : lavoraConNoi.title_posizioni_ru}
        </h1>
        <p className="lead">
          {lang === "it"
            ? lavoraConNoi.desc_posizioni_it
            : lang === "en"
            ? lavoraConNoi.desc_posizioni_en
            : lavoraConNoi.desc_posizioni_ru}
        </p>
      </div>
      {prodottiLavoraConNoi.length !== 0 ? (
        prodottiLavoraConNoi.map((item, key) => (
          <div
            className="container-fluid p-5"
            style={{ backgroundColor: "#F4F4F4" }}
          >
            <div className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5">
              <h1 className="title-about-us pb-3 text-dark ">
                {" "}
                {lang === "it"
                  ? parse(item.title_it)
                  : lang === "en"
                  ? parse(item.title_en)
                  : parse(item.title_ru)}
              </h1>
              <p className="lead">
                {lang === "it"
                  ? parse(item.desc_it)
                  : lang === "en"
                  ? parse(item.desc_en)
                  : parse(item.desc_ru)}
              </p>
              <div>
                <a
                  href="#form"
                  className="btn btn-outline-danger rounded-0 btn-lg w-25 mt-3 mb-3 responsive-btn-home"
                >
                  {Globals.language.workWithUs.candidati[lang]}
                </a>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="p-2 text-center">
          {" "}
          <Card style={{ fontSize: "25px" }}>
            <i
              className="bi bi-check-circle"
              style={{ fontSize: "50px", color: "green" }}
            ></i>
            <h2>{Globals.language.workWithUs.titleNoCand[lang]}</h2>
            <h4>{Globals.language.workWithUs.descNoCand[lang]}</h4>
          </Card>
        </div>
      )}
      {/* <div
        className="container-fluid p-5"
        style={{ backgroundColor: "#F4F4F4" }}
      >
        <div className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5">
          <h1 className="title-about-us pb-3 text-dark">Junior System specialist</h1>
          <p>
            Consulta le posizioni attualmente aperte e inviaci il tuo
            curriculum. Se il tuo profilo è in linea con le nostre richieste, ti
            contatteremo il prima possibile.
          </p>
          <div>
            <btn className="btn btn-outline-danger rounded-0 btn-lg w-25 mt-3 mb-3"> Candidati</btn>
          </div>
        </div>
      </div> */}
      <div
        className="container-fluid p-5"
        style={{ backgroundColor: "#F4F4F4" }}
      >
        <div className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5">
          <h1 className="title-about-us pb-3">
            {Globals.language.workWithUs.titleCandSpontanea[lang]}
          </h1>
          <p className="lead">
            {Globals.language.workWithUs.descCandSpontanea[lang]}
          </p>
          <div>
            <a
              href="#form"
              className="btn btn-outline-danger rounded-0 btn-lg w-25 mt-3 mb-3 responsive-btn-home"
            >
              {" "}
              {Globals.language.workWithUs.btnCandSpontanea[lang]}
            </a>
          </div>
        </div>
      </div>

      <div
        id="form"
        className="container-fluid p-0"
        style={{ backgroundColor: "#F4F4F4" }}
      >
        {
          /* <div className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5">
          <h1 className="title-about-us pb-3">Invia il tuo cv</h1>
          <h6>Candidatura</h6>
          <p>
            form varie
          </p>
          <div>
            <btn className="btn btn-outline-danger rounded-0 btn-lg w-25 mt-3 mb-3 responsive-btn-home"> Invia il tuo CV</btn>
          </div>
        </div> */
          <div className="container col-lg-12 col-md-6 col-sm-12 pt-5 pb-5">
            <div className="column">
              <div>
                <h1 className="title-about-us pb-3 text-center">
                  {Globals.language.contacts.titleCv[lang]}
                </h1>
              </div>
              <div>
                <h5 className="pb-3 text-center">
                  {Globals.language.contacts.descCv[lang]}
                </h5>
              </div>
              <div className="container-fluid p-0">
                <Contact general={true} />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default WorkWithUs;
