let baseUrl = "https://cms.readynet.it/webservices/";
let domain = "https://cms.readynet.it";
let recaptcha_v2_key = process.env.REACT_APP_RECAPTCHA_PRODUCTION_KEY;

if (
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === "development" ||
  window.location.href.toString().includes("readynet.readynet.it") === true
) {
  baseUrl = "https://cmsreadynet.readynet.it/webservices/";
  domain = "https://cmsreadynet.readynet.it";
  recaptcha_v2_key = process.env.REACT_APP_RECAPTCHA_DEV_KEY;
}

const Globals = {
  baseUrl: baseUrl,
  domain: domain,
  recaptcha_v2_key: recaptcha_v2_key,

  GETDATAHOME: baseUrl + "?a=get_user_home_element",
  CONTACT: baseUrl + "?a=general_contact",
  GETLEGALPAGE: baseUrl + "?a=get_legal_page",
  GETPREFERENCECOOKIE: baseUrl + "?a=getPreferencesCookie",
  SAVEPREFERENCECOOKIE: baseUrl + "?a=saveCookiePreferences",
  GETFOOTERCOOKIE: baseUrl + "?a=get_footer_cookie",
  CHECKREDIRECT301: baseUrl + "?a=check_redirect",
  UPDATEREDIRECTCOUNTER: baseUrl + "?a=update_redirect_counter",
  GETALLASERVICEHOME: baseUrl + "?a=get_all_services",
  GETDETAILPRODUCT: baseUrl + "?a=get_detail_product",
  GETDETAILPRODUCTHOME: baseUrl + "?a=get_detail_product_home",
  GETHOMEPAGECONTENT: baseUrl + "?a=get_homepage_content",
  GETCONTENTABOUTUS: baseUrl + "?a=get_about_us",
  GETCONTENTPARTNER: baseUrl + "?a=get_partner",
  GETASSISTENZACLIENTI: baseUrl + "?a=get_assistenza_clienti",
  GETLAVORACONNOI: baseUrl + "?a=get_lavora_con_noi",
  GETCONTACTPAGE: baseUrl + "?a=get_contact_page",
  CONTACT: baseUrl + "?a=general_contact",
  WORKWITHUS: baseUrl + "?a=lavora_con_noi_attachment",
  GETCONTACTERMOFUSE: baseUrl + "?a=get_contact_term_of_use",
  GETLEGALPAGE: baseUrl + "?a=get_legal_page",
  COOKIEPOLICY: baseUrl + "?a=get_cookie_policy",
  GETPREFERENCECOOKIE: baseUrl + "?a=getPreferencesCookie",
  SAVEPREFERENCECOOKIE: baseUrl + "?a=saveCookiePreferences",
  GETFOOTERCOOKIE: baseUrl + "?a=get_footer_cookie",
  GETEMAILDISCLAIMER: baseUrl + "?a=get_email_disclaimer",
  GETPRODUCT: baseUrl + "?a=get_product",


  language: {
    navbar: {
      home: {
        it: "Home",
        en: "Home",
        ru: "",
      },
      howTo: {
        it: "Chi siamo",
        en: "How to",
        ru: "",
      },
      assistenza: {
        it: "Assistenza Clienti",
        en: "Customer Service",
        ru: "",
      },
      partner: {
        it: "Partner",
        en: "Partner",
        ru: "",
      },
      lavoraConNoi: {
        it: "Lavora con noi",
        en: "Work with us",
        ru: "",
      },
      contatti: {
        it: "Contatti",
        en: "Contacts",
        ru: "",
      },
      langIta: {
        it: "Italiano",
        en: "Italian",
        ru: "Italian",
      },
      langEn: {
        it: "Inglese",
        en: "English",
        ru: "English",
      },
      langRU: {
        it: "Russo",
        en: "Russian",
        ru: "Russian",
      },
    },
    detailHome: {
      btn_visit_website: {
        it: "Vai al dettaglio",
        en: "More details",
        ru: "",
      },
      btn_contact: {
        it: "Contattaci",
        en: "Contact us",
        ru: "",
      },
    },
    contacts: {
      campoObbligatorio: {
        it: "Campo obbligatorio",
        en: "Mandatory field ",
        ru: "",
      },

      titleProject: {
        it: "Hai un progetto da realizzare?",
        en: "Have a project to propose?",
        ru: "",
      },
      descProject: {
        it: "Contattaci per una consulenza senza impegno.",
        en: "Contact us for a no-obligation consultation.",
        ru: "",
      },
      titlePartner: {
        it: "Vuoi collaborare con noi?",
        en: "Do you want to start collaboration with us?",
        ru: "",
      },
      descPartner: {
        it: "Contattaci per una nuova partnership.",
        en: "Contact us for a new partnership.",
        ru: "",
      },
      titleScrivici: {
        it: "Scrivici",
        en: "Write to us",
        ru: "",
      },
      descScrivici: {
        it: "Hai domande o desideri ulteriori informazioni sui nostri servizi? Contattaci, saremo felici di rispondere alle tue domande e darti tutte le informazioni di cui hai bisogno.",
        en: "Do you have questions or would you like more information about our services? Contact us, we will be happy to answer your questions and give you all the information you need.",
        ru: "",
      },
      titleCv: {
        it: "Invia il tuo CV",
        en: "Send your CV",
        ru: "",
      },
      descCv: {
        it: "Candidati",
        en: "Candidates",
        ru: "",
      },
      titleCustomers: {
        it: "Contattaci",
        en: "Contact us",
        ru: "",
      },
      sendSuccess: {
        it: "Contatto inviato con successo !",
        en: "Contact sent successfully !",
        ru: "",
      },
      sendTextSuccess: {
        it: "Risponderemo al più presto alla casella di posta all'indirizzo suggerito",
        en: "We will respond as soon as possible to the mailbox at the suggested address",
        ru: "",
      },
      sendSuccessCurriculum: {
        it: "Candidatura inviata con successo !",
        en: "Application sent successfully!",
        ru: "",
      },
      nameCurriculum: {
        it: "Carica il tuo curriculum",
        en: "Upload your CV",
        ru: "",
      },
      nameFormCurriculum: {
        it: "Nessun file selezionato",
        en: "No file selected",
        ru: "",
      },
      nameForm: {
        it: "Nome",
        en: "Name",
        ru: "",
      },
      nameFormPlaceholder: {
        it: "Inserisci il nome",
        en: "Enter the name",
        ru: "",
      },
      surnameForm: {
        it: "Cognome",
        en: "Surname",
        ru: "",
      },
      surnameFormPlaceholder: {
        it: "Inserisci il cognome",
        en: "Enter the surname",
        ru: "",
      },
      emailForm: {
        it: "Email",
        en: "Email",
        ru: "",
      },
      emailFormPlaceholder: {
        it: "Inserisci email",
        en: "Enter email",
        ru: "",
      },
      phoneForm: {
        it: "Numero di telefono",
        en: "Phone number",
        ru: "",
      },
      phoneFormPlaceholder: {
        it: "Inserisci il numero di telefono",
        en: "Enter phone number",
        ru: "",
      },
      ragione: {
        it: "Ragione sociale",
        en: "Company name",
        ru: "",
      },
      ragioneFormPlaceholder: {
        it: "Inserisci la ragione sociale",
        en: "Enter company name",
        ru: "",
      },
      privateForm: {
        it: "Privato",
        en: "Private user",
        ru: "",
      },
      companyForm: {
        it: "Azienda",
        en: "Company",
        ru: "",
      },
      messaggeForm: {
        it: "Messaggio",
        en: "Message",
        ru: "",
      },
      messaggeFormPlaceholder: {
        it: "Inserisci il testo del messaggio",
        en: "Enter the text of the message",
        ru: "",
      },
      messaggeFormPlaceholderCurriculum: {
        it: "Raccontaci qualcosa di te",
        en: "Tell us about yourself",
        ru: "",
      },
      privacyFormFirst: {
        it: "Ho preso visione dell’informativa ",
        en: "I have read the privacy ",
        ru: "",
      },
      privacyFormSecond: {
        it: " e acconsento al trattamento dei miei dati.",
        en: " and consent to the processing of my data.",
        ru: "",
      },
      newsletterForm: {
        it: "Presto il mio consenso per l’iscrizione al servizio di newsletter.",
        en: "I give my consent to subscribe to the newsletter service.",
        ru: "",
      },
      sendButtonForm: {
        it: "Invia",
        en: "Send",
        ru: "",
      },
      sedeOperativa: {
        it: "Sede operativa:",
        en: "Operational headquarters:",
        ru: "",
      },
      phone: {
        it: "Telefono:",
        en: "Phone number:",
        ru: "",
      },
      email: {
        it: "Email:",
        en: "Email:",
        ru: "",
      },
      hours: {
        it: "I nostri orari:",
        en: "Opening hours: ",
        ru: "",
      },
      monday: {
        it: "Lunedì - Venerdì:",
        en: "Monday - Friday:",
        ru: "",
      },
      saturday: {
        it: "Sabato:",
        en: "Saturday:",
        ru: "",
      },
      sunday: {
        it: "Domenica",
        en: "Sunday",
        ru: "",
      },
    },
    cardHome: {
      btnScopriDiPiu: {
        it: "Scopri di più",
        en: "Find out more",
        ru: "",
      },
    },
    workWithUs: {
      candidati: {
        it: "Candidati",
        en: "Send your applications",
        ru: "",
      },
      titleNoCand: {
        it: "Nessuna posizione è attualmente vacante.",
        en: "",
        ru: "",
      },
      descNoCand: {
        it: "Torna a trovarci in futuro per nuove opportunità.",
        en: "",
        ru: "",
      },
      titleCandSpontanea: {
        it: "Vuoi proporti per un altro ruolo?",
        en: "Would you like to propose yourself for another role?",
        ru: "",
      },
      descCandSpontanea: {
        it: "Se non hai trovato quello che cerchi mandaci la tua candidatura spontanea.",
        en: "If you have not found what you are looking for, send us your spontaneous application.",
        ru: "",
      },

      btnCandSpontanea: {
        it: "Invia il tuo cv",
        en: "Send your cv",
        ru: "",
      },
    },
    errorLabel: {
      errorPagelabel: {
        it: "Ci dispiace, ma la pagina cercata non esiste!",
        en: "Sorry, but the page you are looking for does not exist!",
        ru: "",
      },
      comeBackHome: {
        it: "Torna alla home!",
        en: "Return to home!",
        ru: "",
      },
      doLogin: {
        it: "É necessario effettuare il login per effettuare un pagamento",
        en: "You must login to make a payment",
        ru: "",
      },
      noSelectCongress: {
        it: "Non è stato selezionato alcun congresso/evento",
        en: "No congresses/events selected",
        ru: "",
      },
      noAuth: {
        it: "Non sei autenticato per poter eseguire l'ordine",
        en: "You are not authenticated to submit your order",
        ru: "",
      },
      serverError: {
        it: "Errore di comunicazione con il server",
        en: "Communication error with the server",
        ru: "",
      },
      errorPaymentInfo: {
        it: "Errore nel recupero dei dati di pagamento",
        en: "Error in retrieving payment data",
        ru: "",
      },
      acceptRecaptcha: {
        it: "Accettare il recaptcha per poter eseguire la form di contatto",
        en: "Accept recaptcha in order to run the contact form",
        ru: "",
      },
      recaptchaExpired: {
        it: "É necessario riaccettare il checkbox recaptcha, token scaduto",
        en: "You need to re-accept recaptcha checkbox, token expired",
        ru: "",
      },
      logoutError: {
        it: "Errore in fase di logout, sarai reindirizzato alla homepage",
        en: "Error on logout, you will be redirected to the homepage",
        ru: "",
      },
      noDescription: {
        it: "Nessuna descrizione",
        en: "No description",
        ru: "",
      },
      selectTicket: {
        it: "Devi selezionare la quota di iscrizione",
        en: "You have to select a registration fee",
        ru: "",
      },
      deleteUser: {
        it: "Sei sicuro di voler eliminare il tuo utente, l'operazione è irreversibile",
        en: "Are you sure you want to delete your user, the operation is irreversible",
        ru: "",
      },
      label_unauthorized: {
        it: "Accesso non autorizzato, torna alla home",
        en: "Unauthorized, return to home page ",
        ru: "",
      },
      error_unauthorized: {
        it: "ERRORE 401, NON AUTORIZZATO",
        en: "ERROR 401, UNAUTHORIZED ",
        ru: "",
      },
    },
    cookiebar: {
      btn_essenziali: {
        it: "Rifiuta tutti",
        en: "Rejects all ",
        ru: "Zavrača vse",
      },
      btn_all: {
        it: "Accetta tutto",
        en: "All Cookies",
        ru: "Spejmi vse",
      },
      btn_more: {
        it: "Preferenze cookie",
        en: "More options",
        ru: "Nastavitve piškotkov",
      },
      privacy: {
        it: "Privacy",
        en: "privacy",
        ru: "Zasebnosti",
      },
      cookie_necessari: {
        it: "Cookie Necessari (Obbligatori)",
        en: "Essential cookies (Required)",
        ru: "Obvezni piškotki (Obvezni)",
      },
      cookie_analitici: {
        it: "Cookie analitici",
        en: "Analytical cookies",
        ru: "Analitični piškotki",
      },
      cookie_profilazione: {
        it: "Cookie di profilazione",
        en: "Profiling cookie",
        ru: "Piškotki za profiliranje",
      },
      link_privacy: {
        it: "Scopri di più su",
        en: "Find out more",
        ru: "Izvedite več o naši politiki",
      },
      btn_confirm: {
        it: "Conferma",
        en: "Confirm",
        ru: "Potrdite svojo izbiro",
      },
    },
    footer: {
      cookieBtn: {
        it: "Cookie",
        en: "Cookie",
        ru: "",
      },
      company: {
        it: "Azienda",
        en: "Company",
        ru: "podjetje",
      },
      pages: {
        it: "Pagine",
        en: "Pages",
        ru: "strani",
      },
      termOfUse: {
        it: "condizioni d'uso",
        en: "term of use",
        ru: "pogoji uporabe",
      },
      privacy: {
        it: "Privacy",
        en: "Privacy",
        ru: "pravilnik o zasebnosti",
      },
      credits: {
        it: "credits",
        en: "privacy",
        ru: "produkcija",
      },
      login: {
        it: "Login",
        en: "Login",
        ru: "prijavi se",
      },
      cookie: {
        it: "Cookie",
        en: "Cookie",
        ru: "piškotki",
      },
      contactUs: {
        it: "contatti",
        en: "contact details",
        ru: "kontakt",
      },
      gpoint: {
        it: "punti G ",
        en: "G spots",
        ru: "G točke",
      },
      meetUs: {
        it: "incontrarci",
        en: "meeting us",
        ru: "srečanja",
      },
      news: {
        it: "news",
        en: "news",
        ru: "novice",
      },
      fist: {
        it: "READY NET Soc. Unipersonale |",
        en: "READY NET Soc. Unipersonale |",
        ru: "novice",
      },
      second: {
        it: "Vicolo Ognissanti, 6 - 37123 Verona (VR) - Telefono:",
        en: "Vicolo Ognissanti, 6 - 37123 Verona (VR) - Telephone:",
        ru: "",
      },
      third: {
        it: " - Numero REA VR-363265 - P. IVA 03757570233",
        en: " - REA number VR-363265 - VAT number 03757570233",
        ru: "",
      },
      privacy_link: {
        it: "Informativa privacy",
        en: "Privacy policy",
        ru: "",
      },
      cookie_link: {
        it: "Informativa cookie",
        en: "Cookie policy",
        ru: "",
      },
      condizioni_link: {
        it: "Condizioni d'utilizzo",
        en: "Term of use",
        ru: "",
      },
    },
    meta_title: {
      home: {
        it: "Ready Net – Progetti per la Trasformazione Digitale",
        en: "Ready Net – Projects for Digital Transformation",
        ru: "",
      },
      home_desc: {
        it: "Forniamo risposte efficaci con soluzioni personalizzate per soddisfare ogni richiesta. Studiamo progetti 'ad hoc' per ogni azienda, nel contesto di continua evoluzione degli strumenti e delle applicazioni nel campo tecnologico.",
        en: "We provide effective answers with customised solutions to meet every requirement. We study 'ad hoc' projects for each company, in the context of the continuous evolution of tools and applications in the field of technology.",
        ru: "",
      },
      home_key: {
        it: "Ready Net, web agency, digitalizzazione, trasformazione digitale",
        en: "Ready Net, web agency, digitalisation, digital transformation",
        ru: "",
      },
      chi_siamo: {
        it: "Chi siamo",
        en: "About us",
        ru: "",
      },
      chi_siamo_desc: {
        it: "Ready net - Siamo presenti sul mercato dal 2008! Abbiamo lavorato assieme a diverse realtà economiche italiane di ogni dimensione ognuna delle quali aveva a cuore un obiettivo principale: lo sviluppo della propria azienda nel mondo digitale.",
        en: "Ready net - We have been in the market since 2008! We have worked together with various Italian businesses of all sizes, each of which had one main objective at heart: the development of their companies in the digital world.",
        ru: "",
      },
      chi_siamo_key: {
        it: "Ready Net, web agency, digitalizzazione, trasformazione digitale",
        en: "Ready Net, web agency, digitalisation, digital transformation",
        ru: "",
      },
      assistenza: {
        it: "Assistenza clienti",
        en: "Customer service",
        ru: "",
      },
      assistenza_desc: {
        it: "Ready net - Non vi lasceremo mai soli!",
        en: "Ready net - We will never leave you alone!",
        ru: "",
      },
      assistenza_key: {
        it: "Ready Net assistenza clienti, assistenza",
        en: "Ready Net customer service, support",
        ru: "",
      },
      partners: {
        it: "Partner",
        en: "Partner",
        ru: "",
      },
      partners_desc: {
        it: "Ready net - Collaboriamo da sempre con aziende del settore digitale ed associazioni di categoria: l’unione fa la forza!",
        en: "Ready net - We have always collaborated with digital companies and trade associations: unity is strength!",
        ru: "",
      },
      partners_key: {
        it: "partner, Ready Net partner",
        en: "partner, Ready Net partner",
        ru: "",
      },
      lavora_con_noi: {
        it: "Lavora con noi",
        en: "Work with us",
        ru: "",
      },
      lavora_con_noi_desc: {
        it: "Ready net - Siamo sempre alla ricerca di figure professionali da inserire nel nostro team.",
        en: "Ready net - We are always looking for professionals to join our team.",
        ru: "",
      },
      lavora_con_noi_key: {
        it: "Lavora con noi, Lavora con noi Ready Net, lavoro",
        en: "Work with us, Work with us Ready Net, work",
        ru: "",
      },
      contatti: {
        it: "Contatti",
        en: "Contact",
        ru: "",
      },
      contatti_desc: {
        it: "Ready net - Raccontaci le tue idee, siamo qui per ascoltarti.",
        en: "Ready net - Tell us your ideas, we're here to listen.",
        ru: "",
      },
      contatti_key: {
        it: "contatti, idee, contattaci",
        en: "Cocontacts, ideas, contact us",
        ru: "",
      },
      servizi: {
        it: "Ready Net - Servizi",
        en: "Ready Net - Services",
        ru: "",
      },
      prodotto: {
        it: "Ready Net - Prodotto",
        en: "Ready Net - Product",
        ru: "",
      },
      privacy: {
        it: "Informativa privacy - Ready Net",
        en: "Privacy policy - Ready Net",
        ru: "",
      },
      condizioni: {
        it: "Condizioni d'utilizzo - Ready Net",
        en: "Term of use - Ready Net",
        ru: "",
      },
      cookie: {
        it: "Informativa cookie - Ready Net",
        en: "Cookie policy - Ready Net",
        ru: "",
      },
      emailDiscalimer: {
        it: "Email Disclaimer - Ready Net",
        en: "Email Disclaimer - Ready Net",
        ru: "",
      },
    },
  },
};



export default Globals;
