import React, { useState, useEffect, useContext } from "react";
import "../scss/homepage.scss";
import anime from "animejs/lib/anime.es.js";
import axios from "axios";
import Globals from "../parameters/globals";
import GeneralContext from "../context/GeneralContext";
import { Helmet } from "react-helmet";
import principalImage from "../images/principal-image.webp";
function SliderHome() {
  const { lang } = useContext(GeneralContext);
  const [homeContent, setHomeContent] = useState([]);
  const [imageBg, setImageBg] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHomeContent();
  }, [lang]);

  const getHomeContent = async () => {
    setLoading(true);

    try {
      setLoading(false);

      const response = await axios.get(Globals.GETHOMEPAGECONTENT);
      setHomeContent(response?.data?.homecontent);
      setImageBg(response?.data?.image_bg_home);
    } catch (err) {
      setLoading(true);

      console.log("errore");
    }
  };
  const animationRef = React.useRef(null);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 300 + 30 * i,
    });
  }, []);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16 .letter",
      translateY: [0, -100],
      opacity: [1, 0],
      easing: "easeInExpo",
      duration: 1200,
      delay: (el, i) => 100 + 30 * i,
    });
  }, []);

  return (
    <>
      <header>
        <h1 style={{ display: "none" }}>
          {Globals.language.meta_title.home[lang]}
        </h1>
      </header>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Globals.language.meta_title.home[lang]}</title>
        <meta
          name="description"
          content={Globals.language.meta_title.home_desc[lang]}
        />
        <meta
          name="keywords"
          content={Globals.language.meta_title.home_key[lang]}
        />
        <link rel="canonical" href="https://www.readynet.it/" />
      </Helmet>
      <div
        className="bg"
        style={{
          backgroundImage: "url(" + imageBg + ")",
          width: "100%",
          height: "100%",
        }}
      ></div>
      <div className="content">
        <header>
          <h1 className="title-principal ml16">
            {lang === "it"
              ? homeContent.titolo_it
              : lang === "en"
              ? homeContent.titolo_en
              : homeContent.titolo_ru}{" "}
          </h1>
          <p className="desc-principal-home">
            {lang === "it"
              ? homeContent.desc_it
              : lang === "en"
              ? homeContent.desc_en
              : homeContent.desc_ru}
          </p>
        </header>
      </div>
      <a href="#prodottiHome" className="arrow"></a>
      <div id="prodottiHome"></div>
    </>
  );
}
export default SliderHome;
