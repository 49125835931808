import React from "react";
import { useNavigate } from "react-router-dom";
import Error404 from "../components/Error404";
function ErrorPage() {
	const navigate = useNavigate();
	return (
		<div>
			{" "}
			<div>
			<Error404/>
			</div>
			
		</div>
	);
}

export default ErrorPage;
