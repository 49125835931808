import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Globals from "../parameters/globals";
import GeneralContext from "../context/GeneralContext";
import { Helmet } from "react-helmet";

function CookiePolicy() {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [TermOfUse, setTermOfUse] = useState([]);
  const { lang } = useContext(GeneralContext);

  useEffect(() => {
    getTermOfUse();
  }, [lang]);

  const getTermOfUse = async () => {
    setError(false);
    setMessage("");

    try {
      const response = await axios.get(Globals.COOKIEPOLICY, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response?.data?.error === true) {
        setError(true);
        setMessage(response?.data?.error);
      } else {
        setTermOfUse(
          lang === "it"
            ? response?.data?.term.htmlcontents_it
            : lang === "en"
            ? response?.data?.term.htmlcontents_en
            : response?.data?.term.htmlcontents_ru
        );
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    } catch (err) {
      setError(true);
      setMessage(err);
    }
  };

  return (
    <>
      {error ? (
        <section className="text-center">
          {" "}
          <h2> {message}</h2>
        </section>
      ) : (
        <div style={{ minHeight: "750px" }}>
          <div
            style={{
              backgroundColor: "#e83e30",
              width: "100%",
              height: "130px",
            }}
          >
            <Helmet>
              <meta charSet="utf-8" />
              <title>{Globals.language.meta_title.cookie[lang]}</title>
              <link rel="canonical" href="https://www.readynet.it/" />
              <meta name="robots" content="noindex" />
            </Helmet>
          </div>
          <div
            className="container"
            style={{ marginTop: "140px", marginBottom: "100px" }}
          >
            <div dangerouslySetInnerHTML={{ __html: TermOfUse }} />
          </div>
        </div>
      )}
    </>
  );
}

export default CookiePolicy;
