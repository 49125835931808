import React, { useState, useEffect, useContext, useRef } from "react";
import AboutUsImage from "../images/ChiSiamo.png";
import "../scss/aboutus.scss";
import anime from "animejs/lib/anime.es.js";
import axios from "axios";
import Globals from "../parameters/globals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneralContext from "../context/GeneralContext";
import Contact from "../components/Contact";
import { Helmet } from "react-helmet";

function Partner() {
  const animationRef = React.useRef(null);
  const { lang } = useContext(GeneralContext);
  const [partnerContent, setPartnerContent] = useState([]);
  const [imageBg, setImageBg] = useState([]);
  const [imagePartner, setImagePartner] = useState([]);
  const [imageMission, setImageMission] = useState([]);
  const [galleryPartner, setgGlleryPartner] = useState([]);
  const anchorRef = useRef(null);

  useEffect(() => {
    getPartnersContent();
  }, [lang]);

  const getPartnersContent = async () => {
    try {
      const response = await axios.get(Globals.GETCONTENTPARTNER);
      setPartnerContent(response?.data?.partner);
      setImageBg(response?.data?.image_bg_about);
      setImagePartner(response?.data?.image_partner);
      setImageMission(response?.data?.image_mission);
      setgGlleryPartner(response?.data?.immagini_multiple_partner);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } catch (err) {
      console.log("errore");
    }
  };
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 300 + 30 * i,
    });
  }, []);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16 .letter",
      translateY: [0, -100],
      opacity: [1, 0],
      easing: "easeInExpo",
      duration: 1200,
      delay: (el, i) => 100 + 30 * i,
    });
  }, []);
  const goToObject = () => {
    const offset = -100; // Numero di pixel da alzare
    const top =
      anchorRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      offset;
    window.scrollTo({ top, behavior: "smooth" });
  };
  return (
    <div>
       <h1 style={{display:"none"}}>
      {Globals.language.meta_title.partners[lang]}
      </h1>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Globals.language.meta_title.partners[lang]}</title>
        <meta name="description" content={Globals.language.meta_title.partners_desc[lang]} />
        <meta name="keywords" content={Globals.language.meta_title.partners_key[lang]} />
        <link rel="canonical" href="https://readynet.it/partner" />
      </Helmet>
      <div>
        <div
          className="bg"
          style={{
            backgroundImage: "url(" + imageBg + ")",
            width: "100%",
            height: "100%",
          }}
        ></div>
        {/* <div className="container-fluid p-0">
  <div className="dummy"  style={{
            backgroundImage: "url(" + imageBg + ")",
            width: "100%",
            height: "100%",
          }}></div>
</div> */}
        <div className="content">
          <header>
            <h1 className="title-principal ml16">
              {lang === "it"
                ? partnerContent.title_it
                : lang === "en"
                ? partnerContent.title_en
                : partnerContent.title_ru}{" "}
            </h1>
            <p className="desc-principal">
              {lang === "it"
                ? partnerContent.desc_it
                : lang === "en"
                ? partnerContent.desc_en
                : partnerContent.desc_ru}
            </p>
          </header>
        </div>
        <button className="arrow" onClick={() => goToObject()}></button>

        {/* <div className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5">
          <h1 className="title-about-us pb-3">
            {lang === "it"
              ? partnerContent.title_partner_it
              : lang === "en"
              ? partnerContent.title_partner_en
              : partnerContent.title_partner_ru}
          </h1>
          <p className="lead">
            {lang === "it"
              ? partnerContent.desc_partner_it
              : lang === "en"
              ? partnerContent.desc_partner_en
              : partnerContent.desc_partner_ru}
          </p>
        </div> */}
      </div>

      {/* <div className="container-fluid">
        <div id="div_wrapper">
          <div className="div_1" style={{ marginTop: 50, marginBottom: 50 }}>
            <div className="div_2">
              <div
                className="image-bg"
                style={{
                  backgroundImage: "url(" + imagePartner + ")",
                  width: "100%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5">
        <h1 className="title-about-us pb-3">
          {lang === "it"
            ? partnerContent.title_mission_it
            : lang === "en"
            ? partnerContent.title_mission_en
            : partnerContent.title_mission_ru}
        </h1>
        <p className="lead">
          {lang === "it"
            ? partnerContent.desc_mission_it
            : lang === "en"
            ? partnerContent.desc_mission_en
            : partnerContent.desc_mission_ru}
        </p>
      </div>

      <div className="container-fluid">
        <div id="div_wrapper">
          <div className="div_1" style={{ marginTop: 50, marginBottom: 50 }}>
            <div className="div_2">
              <div
                className="image-bg"
                style={{
                  backgroundImage: "url(" + imageMission + ")",
                  width: "100%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div> */}

      <section
        className="section section-light mt-none mb-none"
        ref={anchorRef}
        id="anchor"
      >
        <div className="container">
          {/* <h2 className="mb-sm text-center title-homepage-all ">
            {galleryPartner.length !== 0 ? "Test" : ""}
          </h2> */}
          <div className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5">
            <h1 className="title-about-us pb-3">
              {lang === "it"
                ? partnerContent.title_mission_it
                : lang === "en"
                ? partnerContent.title_mission_en
                : partnerContent.title_mission_ru}
            </h1>
            <p className="lead">
              {lang === "it"
                ? partnerContent.desc_mission_it
                : lang === "en"
                ? partnerContent.desc_mission_en
                : partnerContent.desc_mission_ru}
            </p>
          </div>
          <strong>
            <div className="row">
              {galleryPartner !== false && galleryPartner.length !== 0 ? (
                galleryPartner.map((item, key) => (
                  <div className="col-sm-6 col-md-4 col-lg-4" key={key}>
                    <a target="_blank" href={item.title}>
                      <div className="square-holder ">
                        <img
                          loading="lazy"
                          fetchpriority="high"
                          alt=""
                          className="img-responsive image-card-clienti"
                          src={item.original}
                        />
                      </div>
                    </a>

                    <div className="mx-auto text-center"></div>
                  </div>
                ))
              ) : (
                <div></div>
              )}
            </div>
          </strong>
        </div>
      </section>

      <div className="container col-lg-12 col-md-6 col-sm-12 pt-5 pb-5">
        <div className="column">
          <div>
            <h1 className="title-about-us pb-3 text-center">
              {Globals.language.contacts.titlePartner[lang]}{" "}
            </h1>
          </div>
          <div>
            <h5 className="pb-3 text-center">
              {Globals.language.contacts.descPartner[lang]}{" "}
            </h5>
          </div>
          <div className="container-fluid">
            <Contact
              general={false}
              from={"Chi siamo - " + partnerContent.name_it}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner;
