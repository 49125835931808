import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/footer.scss";
import Globals from "../parameters/globals";
import GeneralContext from "../context/GeneralContext";
import axios from "axios";
import { Button } from "react-bootstrap";

function Footer(props) {
  const { lang } = useContext(GeneralContext);
	let now = new Date().getFullYear();

  let modifyCookie = props.modifyCookie || [];
  const [activeCookiebar, setActiveCookiebar] = useState(0);

  const getCookiebar = async () => {
    try {
      const response = await axios.get(Globals.GETFOOTERCOOKIE);
      setActiveCookiebar(parseInt(response.data.active_cookie[0].cookie_bar));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCookiebar();
  }, []);

  return (
    <div className="footer">
      <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12 mb-4">
            <div className="single_footer">
              <h4 className="title-first">
                {Globals.language.footer.fist[lang]}{" "}
                <span className="desc-first">
                  {Globals.language.footer.second[lang]} <a href={"Tel:" + "+39 0458012303"}>+39 0458012303</a>{Globals.language.footer.third[lang]} {" "}
                </span>
              </h4>
              <div className="text-center copyright">
                Copyright &copy; {now} <b>Ready Net srl</b>. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center container-footer">
          <div className="col-lg-2 col-sm-12 col-xs-12 border-right-footer">
            <p className="copyright">
              <Link to={lang + "/privacy"}>
                {" "}
                {Globals.language.footer.privacy_link[lang]}{" "}
              </Link>
            </p>
          </div>
          <div className="col-lg-2 col-sm-12 col-xs-12 border-right-footer">
            <p className="copyright">
              <Link to={lang + "/condizioni-utilizzo"}>
                {Globals.language.footer.condizioni_link[lang]}{" "}
              </Link>
            </p>
          </div>
          <div className="col-lg-2 col-sm-12 col-xs-12 border-right-footer">
            <p className="copyright">
              <Link to={lang + "/cookie-policy"}>
                {" "}
                {Globals.language.footer.cookie_link[lang]}{" "}
              </Link>
            </p>
          </div>
          <div className="col-lg-2 col-sm-12 col-xs-12">
            <p className={activeCookiebar !== 0 ? "copyright" : "copyright-custom"}>
              <a href={Globals.domain} target="_blank">
                Login
              </a>
            </p>
          </div>
          {activeCookiebar !== 0 ? (

          <div className="col-lg-2 col-sm-12 col-xs-12">
              <>
                <p className="copyright-custom">
                  <Button
                    className="mx-2 btn btn-link p-0 bg-transparent text-dark"
                    style={{ fontSize: "14px", textDecoration: "none" }}
                    onClick={modifyCookie}
                  >
                    {Globals.language.footer.cookie[lang]}
                  </Button>
                </p>
              </>
          
          </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Footer;
