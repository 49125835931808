import { ThreeDots } from "react-loader-spinner";
import "../scss/loading.scss";

import React from "react";

function Loading() {
	return (
			<article style={{ textAlign: "center", display: "flex", height: "50em", justifyContent:"center", alignItems:"center" }}>

			<ThreeDots
				color='#cd3426'
				ariaLabel='three-dots-loading'
				wrapperStyle={{
					width: "80px",
					
				}}
				wrapperClassName='loader'
				visible={true}
			/>
			</article>
		
	);
}

export default Loading;
