import React, {useState} from 'react';
import "../scss/scrollButton.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ScrollButton = () =>{

const [visible, setVisible] = useState(false)

const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 300){
	setVisible(true)
	}
	else if (scrolled <= 300){
	setVisible(false)
	}
};

const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};

window.addEventListener('scroll', toggleVisible);

return (

<div style={{display: visible ? 'inline' : 'none'}}>
    <a href="#"  className="btn-top-pulse" onClick={scrollToTop}>
	<i className="fa fa-arrow-up"></i>

</a>
</div>

);
}

export default ScrollButton;
