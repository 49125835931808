import "../scss/error404.scss";
// import { Helmet } from "react-helmet";
import GeneralContext from "../context/GeneralContext";
import React, {useContext} from "react";
import Globals from "../parameters/globals";

const Error = () => {

	const { lang } = useContext(GeneralContext);

	return (
		<div className='error-404'>
			<div>
			<div
            style={{
              backgroundColor: "#e83e30",
              width: "100%",
              height: "130px",
            }}
          ></div>
			<div
				className='row w-100'
				style={{ marginTop: "200px", marginBottom: "150px" }}>
				<div className='col-md-12'>
					<div className='error-template'>
						<h1>Oops!</h1>
						<h2>404 Not Found</h2>
						<div className='error-details'>
							{Globals.language.errorLabel.errorPagelabel[lang]}
						</div>
						<div className='error-actions'>
							<a
								href='/'
								className='btn text-light btn-lg'
								style={{ backgroundColor: "#e83e30" }}>
								<span className='glyphicon glyphicon-home'></span>
								{Globals.language.errorLabel.comeBackHome[lang]}
							</a>
						</div>
					</div>
				</div>
			</div>
			</div>

		</div>
	);
};

export default Error;
