import React, { useEffect, useState, useContext, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../scss/navbar.scss";
import logo from "../images/logo-sito.png";
import logoColori from "../images/logoColori.png";
import logoBianco from "../images/logo_bianco.png";
import Globals from "../parameters/globals";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import GeneralContext from "../context/GeneralContext";
import { useLocation } from "react-router-dom";

function Navbar() {
  let location = useLocation();
  const { lang, setLang } = useContext(GeneralContext);

  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const [stickyClass, setStickyClass] = useState("");
  const [isActive, setActive] = useState(lang);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;

      setHeight(windowHeight);

      // window height changed for the demo
      windowHeight > 80 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };
  const getPageFromUrl = (changeLang, paramLang) => {
    localStorage.setItem("lang", paramLang);
    setActive(paramLang);
    let path = window.location.pathname;

    if (path === "/") {
      navigate(changeLang);
      setLang(paramLang);
    }

    if (
      path.slice(0, 4) === "/it/" ||
      path.slice(0, 4) === "/ru/" ||
      path.slice(0, 4) === "/en/"
    ) {
      navigate(changeLang + path.slice(4));
      setLang(paramLang);
    } else {
      navigate(changeLang + (path.slice(0, 1) !== "/" ? path : path.slice(1)));
      setLang(paramLang);
    }
  };
  const goToObject = () => {
    const offset = 10; // Numero di pixel da alzare
    const top =
      anchorRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      offset;
    window.scrollTo({ top, behavior: "smooth" });
  };
  return (
    <div className="App" ref={anchorRef} id="anchor">
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav
        className={`navbar ${stickyClass}`}
        style={{ background: click ? "#000" : "" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="nav-container " onClick={() => goToObject()}>
          <NavLink to="/" className="nav-logo ">
            <img
              
              className="logo-navbar"
              src={height > 80 ? logo : logoBianco}
              alt="Logo principale Ready net"
            ></img>
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <div
              className="resposive-lang "
              style={{
                position: click ? "absolute" : "absolute",
                top: "5px",
                left: "5px",
              }}
            >
              {/* <Form.Select size="sm" defaultValue={lang}>
                <option value="it" onClick={() => getPageFromUrl("it/", "it")}>
                  🇮🇹&emsp;{Globals.language.navbar.langIta[lang]}
                </option>
                <option value="ru" onClick={() => getPageFromUrl("ru/", "ru")}>
                  🇷🇺&emsp;{Globals.language.navbar.langRU[lang]}
                </option>

                <option value="en" onClick={() => getPageFromUrl("en/", "en")}>
                  🇬🇧&emsp;{Globals.language.navbar.langEn[lang]}
                </option>
              </Form.Select> */}

              {/* <button
                className={
                  height > 80
                    ? isActive === "it"
                      ? "btn btn-lang-active-custom rounded-0 text-dark"
                      : "btn text-dark color-mobile"
                    : isActive === "it"
                    ? "btn btn-lang-active rounded-0 text-light"
                    : "btn text-light color-mobile"
                }
                onClick={() => getPageFromUrl("it/", "it")}
              >
                🇮🇹&emsp;{Globals.language.navbar.langIta[lang]}
              </button>
    
                  <button
                className={
                  height > 80
                    ? isActive === "ru"
                      ? "btn btn-lang-active-custom rounded-0 text-dark"
                      : "btn text-dark color-mobile"
                    : isActive === "ru"
                    ? "btn btn-lang-active rounded-0 text-light"
                    : "btn text-light color-mobile"
                }
                onClick={() => getPageFromUrl("ru/", "ru")}
              >
                  🇷🇺&emsp;{Globals.language.navbar.langRU[lang]}
              </button>
              <button
                className={
                  height > 80
                    ? isActive === "en"
                      ? "btn btn-lang-active-custom rounded-0 text-dark"
                      : "btn text-dark color-mobile"
                    : isActive === "en"
                    ? "btn btn-lang-active rounded-0 text-light"
                    : "btn text-light color-mobile"
                }
                onClick={() => getPageFromUrl("en/", "en")}
              >
                🇬🇧&emsp;{Globals.language.navbar.langEn[lang]}{" "}
              </button> */}
            </div>
            {/* onClick={() => click ? handleClick : window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) ? "" : null  } */}

            <li className={height > 80 ? "nav-item-custom" : "nav-item"}>
              <NavLink
                to="/"
                activeclassname="active"
                className={height > 80 ? "nav-links-custom" : "nav-links"}
                onClick={click ? handleClick : null}
              >
                {Globals.language.navbar.home[lang]}
              </NavLink>
            </li>
            <li className={height > 80 ? "nav-item-custom" : "nav-item"}>
              <NavLink
                to="/chi-siamo"
                activeclassname="active"
                className={height > 80 ? "nav-links-custom" : "nav-links"}
                onClick={click ? handleClick : null}
              >
                {Globals.language.navbar.howTo[lang]}
              </NavLink>
            </li>
            <li className={height > 80 ? "nav-item-custom" : "nav-item"}>
              <NavLink
                to="/assistenza-clienti"
                activeclassname="active"
                className={height > 80 ? "nav-links-custom" : "nav-links"}
                onClick={click ? handleClick : null}
              >
                {Globals.language.navbar.assistenza[lang]}
              </NavLink>
            </li>
            <li className={height > 80 ? "nav-item-custom" : "nav-item"}>
              <NavLink
                to="/partner"
                activeclassname="active"
                className={height > 80 ? "nav-links-custom" : "nav-links"}
                onClick={click ? handleClick : null}
              >
                {Globals.language.navbar.partner[lang]}
              </NavLink>
            </li>
            <li className={height > 80 ? "nav-item-custom" : "nav-item"}>
              <NavLink
                to="/lavora-con-noi"
                activeclassname="active"
                className={height > 80 ? "nav-links-custom" : "nav-links"}
                onClick={click ? handleClick : null}
              >
                {Globals.language.navbar.lavoraConNoi[lang]}
              </NavLink>
            </li>
            <li className={height > 80 ? "nav-item-custom" : "nav-item"}>
              <NavLink
                to="/contatti"
                activeclassname="active"
                className={height > 80 ? "nav-links-custom" : "nav-links"}
                onClick={click ? handleClick : null}
              >
                {Globals.language.navbar.contatti[lang]}
              </NavLink>
            </li>
            <div
              className={
                height > 80
                  ? "d-flex justify-content-center mt-0"
                  : "d-flex justify-content-center mt-1"
              }
            >
              <a href="https://www.facebook.com/readynetsrl/" target="_blank">
                <div
                  className={
                    height > 80 ? "circle-icons-custom" : "circle-icons"
                  }
                >
                  {" "}
                  <i
                    className={
                      height > 80
                        ? "fa fa-facebook text-dark"
                        : "fa fa-facebook text-white"
                    }
                  ></i>
                </div>
              </a>

              <a
                href="https://it.linkedin.com/company/ready-net-srl?original_referer=https%3A%2F%2Fwww.google.com%2F"
                target="_blank"
              >
                <div
                  className={
                    height > 80
                      ? "circle-icons-custom mx-2"
                      : "circle-icons mx-2"
                  }
                >
                  {" "}
                  <i
                    className={
                      height > 80
                        ? "fa fa-linkedin text-dark"
                        : "fa fa-linkedin text-white"
                    }
                  ></i>
                </div>
              </a>
            </div>
            {/* <div>
              <div>
                <a
                href="http://217.64.202.146/areasms/login_utente.php?CodiceUtente=1632"
                target="_blank"
                  className={
                    height > 80
                      ? "btn btn-outline-dark mx-2"
                      : "btn btn-outline-light mx-2"
                  }
                >
                  Sms service <i className="fa fa-external-link"></i>
                </a>
              </div>
            </div> */}
          </ul>

          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-close" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
    </div>
  );
  // }
}

export default Navbar;
