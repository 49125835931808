import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Globals from "../parameters/globals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneralContext from "../context/GeneralContext";

import {
  faComputer,
  faAppleWhole,
  faMobileAndroid,
  faShield,
  faShieldHalved,
  faUserGear,
  faAtom,
  faCloudArrowUp,
  faServer,
  faGears,
} from "@fortawesome/free-solid-svg-icons";

function CardHome() {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [services, setServices] = useState([]);
  const { lang } = useContext(GeneralContext);
  const navigate = useNavigate();

  useEffect(() => {
    getAllServices();
  }, [lang]);

  const getAllServices = async () => {
    try {
      const response = await axios.get(Globals.GETALLASERVICEHOME);
      setServices(response?.data?.servizi);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    } catch (err) {
      console.log("errore");
    }
  };

  return (
    <div>
      <section className="bg-light shadow-sm">
        <div className="container-fluid">
          <div className="row">
            {services.map((item, key) => (
              <div
                key={key}
                className="col-lg-6 d-flex align-items-stretch p-0 border-radius-none"
              >
                <div
                  className="card rounded-0"
                  style={{ width: "100%", height: "600px" }}
                >
                  {/* <img src="https://i.postimg.cc/4xVY64PV/porto-timoni-double-beach-corfu-greece-700.jpg" className="card-img-top" alt="Card Image"/> */}
                  <div className="card-body d-flex flex-column text-center justify-content-center align-items-center align-content-center">
                    <h5 className="card-title">
                      {" "}
                      <FontAwesomeIcon
                        icon={item.icons_fortawesome}
                        style={{
                          color: "#000",
                          fontSize: "35px",
                        }}
                      />
                      {/* <FontAwesomeIcon
                      icon={faComputer}
                      style={{
                        color: "#000",
                        fontSize: "45px",
                      }}
                    /> */}
                    </h5>
                    <div className="circle-home"></div>
                    <p
                      className="card-text mb-4"
                      style={{ fontSize: "43px",maxWidth:550, fontWeight: "600", lineHeight:1 }}
                    >
                      {lang === "it"
                        ? item.nome_it
                        : lang === "en"
                        ? item.nome_en
                        : item.nome_ru}
                    </p>
                    <p
                      style={{
                        fontSize: "20px",
                        lineHeight: 1.2,
                        width: "350px",
                      }}
                      className="card-text mb-4"
                    >
                      {lang === "it"
                        ? item.short_desc_it
                        : lang === "en"
                        ? item.short_desc_en
                        : item.short_desc_ru}
                    </p>
                    <button
                      onClick={() => navigate("/" + (lang === "it"? "area": "section") + "/" + item.id + "/" + (lang === "it"? item.nome_it.replace(/\s/g, "-").toLowerCase(): item.nome_en.replace(/\s/g, "-").toLowerCase()))}
                      className="rounded-0 btn btn-outline-danger mt-2 w-25 p-3 responsive-btn-home"
                    >
                      {Globals.language.cardHome.btnScopriDiPiu[lang]}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default CardHome;
