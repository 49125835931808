import React, { useState, useEffect, useContext, useRef } from "react";
import AboutUsImage from "../images/ChiSiamo.png";
import "../scss/aboutus.scss";
import anime from "animejs/lib/anime.es.js";
import axios from "axios";
import Globals from "../parameters/globals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneralContext from "../context/GeneralContext";
import Contact from "../components/Contact";
import { Helmet } from "react-helmet";

function AboutUs() {
  const animationRef = React.useRef(null);
  const { lang } = useContext(GeneralContext);
  const [aboutContent, setAboutContent] = useState([]);
  const [imageBg, setImageBg] = useState([]);
  const [imageAzienda, setImageAzienda] = useState([]);
  const [imageMission, setImageMission] = useState([]);
  const anchorRef = useRef(null);

  useEffect(() => {
    getAboutUsContent();
  }, [lang]);

  const getAboutUsContent = async () => {
    try {
      const response = await axios.get(Globals.GETCONTENTABOUTUS);
      setAboutContent(response?.data?.aboutUs);
      setImageBg(response?.data?.image_bg_about);
      setImageAzienda(response?.data?.image_azienda);
      setImageMission(response?.data?.image_mission);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } catch (err) {
      console.log("errore");
    }
  };
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16",
      translateY: [100, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 300 + 30 * i,
    });
  }, []);
  React.useEffect(() => {
    animationRef.current = anime({
      targets: ".ml16 .letter",
      translateY: [0, -100],
      opacity: [1, 0],
      easing: "easeInExpo",
      duration: 1200,
      delay: (el, i) => 100 + 30 * i,
    });
  }, []);

  const goToObject = () => {
    const offset = -100; // Numero di pixel da alzare
    const top =
      anchorRef.current.getBoundingClientRect().top +
      window.pageYOffset +
      offset;
    window.scrollTo({ top, behavior: "smooth" });
  };
  return (
    <div>
       <h1 style={{display:"none"}}>
       {Globals.language.meta_title.chi_siamo[lang]}    
        </h1>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Globals.language.meta_title.chi_siamo[lang]}</title>
        <meta name="description" content={Globals.language.meta_title.chi_siamo_desc[lang]} />
        <meta name="keywords" content={Globals.language.meta_title.chi_siamo_key[lang]} />
        <link rel="canonical" href="https://readynet.it/chi-siamo" />
      </Helmet>
      <div>
        <div
          className="bg"
          style={{
            backgroundImage: "url(" + imageBg + ")",
            width: "100%",
            height: "100%",
          }}
        ></div>
        <div className="content">
          <header>
            <h1 className="title-principal ml16">
              {lang === "it"
                ? aboutContent.title_it
                : lang === "en"
                ? aboutContent.title_en
                : aboutContent.title_ru}{" "}
            </h1>
            <p className="desc-principal">
              {lang === "it"
                ? aboutContent.desc_it
                : lang === "en"
                ? aboutContent.desc_en
                : aboutContent.desc_ru}
            </p>
          </header>
        </div>

        {/* <a href="#aboutus" className="arrow"></a> */}
        <button className="arrow" onClick={() => goToObject()}></button>
        {/* <button onClick={() => anchorRef.current.scrollIntoView({ behavior: 'smooth' })}>
        Vai all'anchor
      </button> */}
        <div
          className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5"
          ref={anchorRef}
          id="anchor"
        >
          <h1 className="title-about-us pb-3">
            {lang === "it"
              ? aboutContent.title_azienda_it
              : lang === "en"
              ? aboutContent.title_azienda_en
              : aboutContent.title_azienda_ru}
          </h1>
          <p className="lead">
            {lang === "it"
              ? aboutContent.desc_azienda_it
              : lang === "en"
              ? aboutContent.desc_azienda_en
              : aboutContent.desc_azienda_ru}
          </p>
        </div>
      </div>

      <div className="container-fluid">
        <div id="div_wrapper">
          <div className="div_1" style={{ marginTop: 50, marginBottom: 50 }}>
            <div className="div_2">
              <div
                className="image-bg"
                style={{
                  backgroundImage: "url(" + imageAzienda + ")",
                  width: "100%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="container col-lg-12 col-md-6 col-sm-12 text-center pt-5 pb-5">
        <h1 className="title-about-us pb-3">
          {lang === "it"
            ? aboutContent.title_mission_it
            : lang === "en"
            ? aboutContent.title_mission_en
            : aboutContent.title_mission_ru}
        </h1>
        <p className="lead">
          {lang === "it"
            ? aboutContent.desc_mission_it
            : lang === "en"
            ? aboutContent.desc_mission_en
            : aboutContent.desc_mission_ru}
        </p>
      </div>

      <div className="container-fluid">
        <div id="div_wrapper">
          <div className="div_1" style={{ marginTop: 50, marginBottom: 50 }}>
            <div className="div_2">
              <div
                className="image-bg"
                style={{
                  backgroundImage: "url(" + imageMission + ")",
                  width: "100%",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className="container col-lg-12 col-md-6 col-sm-12 pt-5 pb-5">
        <div className="column">
          <div>
            <h1 className="title-about-us pb-3 text-center">
              {Globals.language.contacts.titleProject[lang]}{" "}
            </h1>
          </div>
          <div>
            <h5 className="pb-3 text-center">
              {Globals.language.contacts.descProject[lang]}{" "}
            </h5>
          </div>
          <div className="container-fluid">
            <Contact
              general={false}
              from={"Chi siamo - " + aboutContent.name_it}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
