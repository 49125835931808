import React from "react";
import CardHome from "../components/CardHome";
import Footer from "../components/Footer";
import SliderHome from "../components/SliderHome";
import HomeImage from "../images/Home.png";
import "../scss/homepage.scss";
function Homepage() {
  return (
    <div>
      <SliderHome />
      <CardHome />
    </div>
  );

  // <div className="princi">
  // 	<img className="image-homepage" src={HomeImage} style={{width:"100%"}}></img>
  // 	<div className="container-principal text-light" >
  // 		<h2 style={{fontSize:"75px", fontWeight:600}}>Trasformazione Digitale <br></br> in corso ...</h2>
  // 		<h5 style={{fontSize:"25px"}}>Supportiamo e sviluppiamo progetti nel mondo digitale <br></br> per imprese di ogni dimensione</h5>
  // 	</div>
  // </div>
}

export default Homepage;
